import React from "react";
import { useSelector } from "react-redux";
import ArticleCard from "Components/article_card";
import PropTypes from "prop-types";

const ArticleVideo = ({ isMobile }) => {

	const device = isMobile ? "mobile" : "desktop";
	const discoverVideos = useSelector((state) => state.storeDiscoverVideo.data);

	return (
		<div className={`video-articles video-articles--${device}`}>
			<div className={`video-articles__articles-content video-articles__articles-content--${device}`}>
				{
					Array.isArray(discoverVideos?.data) && discoverVideos?.data?.map((item) => (
						<div className="small" key={item.id}>
							<ArticleCard
								isMobile={isMobile}
								type={isMobile ? "SMALL" : "SMALL-2"}
								fontSize="20px"
								title={item.title}
								category={item?.category?.[0]?.name || null}
								catSlug={item?.category?.[0]?.slug || null}
								image={item.image || process.env.NEXT_PUBLIC_S3_IMAGE_EMPTY_2_1}
								author={item.author || process.env.NEXT_PUBLIC_IMAGE_NO_USER}
								date={item.created_at}
								slug={item.slug}
								isDate
							/>
						</div>
					))
				}

			</div>

			<div className={`video-articles__link video-articles__link--${device}`}>
				<a href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/video-blog`}>
					<p className="video-articles__link-more video-articles__link-more--mobile">
						READ MORE
						<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`} alt="ic-arrow-right" width="17" />
					</p>
				</a>
			</div>

			<style jsx>
				{`
                    .video-articles {
						width: 100%;
					}

					.video-articles--mobile :global(.title ) {
            			-webkit-line-clamp: 2;
					}

					.video-articles__articles-content {
						display: flex;
						flex-direction: column;
						gap: 24px;
					}
					
					.video-articles__link {
						display: flex;
						justify-content: flex-end;
					}

					.video-articles__link--mobile {
						justify-content: center;
					}

					.video-articles__link-more {
						font-family: Poppins, sans-serif;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: var(--space-0);
						border-bottom: 1px solid #000;
						width: 126px;
						padding: var(--space-20) var(--space-0) var(--space-8) var(--space-0);
						text-align: center;
						justify-content: space-between;
						display: flex;
						font-style: normal;
						color: #000000;
						margin-top: var(--space-24);
					}

					.video-articles__link-more--mobile {
						padding-top: var(--space-16);
						margin-top: var(--space-16);
					}
                `}
			</style>
		</div>
	);

};

ArticleVideo.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default ArticleVideo;
