import React, { useRef } from "react";
import Slider from "react-slick";
import ArticleCard from "Components/review_card";
import Skeleton from "Components/skeleton";

const mobile = ({ ...props }) => {

	const prop = props;
	const refSlider = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	const handleNext = () => {

		refSlider.current.slickNext();

	};
	const handlePrev = () => {

		refSlider.current.slickPrev();

	};

	return (
		<div className="container" data-title="">
			<p className="title">
				MD
				<br />
				REVIEW
			</p>
			<a href={prop.linkMore} className="linkref">
				<p className="txt-link">

					SEE ALL
					&nbsp;
					<img style={{ color: "#fff" }} src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-white.svg`} alt="ic-arrow-white" width="17" />
				</p>
			</a>
			<div className="list-menu">
				<button type="button" className={`menu ${prop.active === "product" ? "active" : ""}`} onClick={() => prop.handleReview("product")}>Product</button>
				<button type="button" className={`menu ${prop.active === "service" ? "active" : ""}`} onClick={() => prop.handleReview("service")}>Service</button>
			</div>
			<section className="article-update">
				{
					!prop.Review?.data?.results
					&& (
						<div className="loading">
							<Skeleton
								width="100%"
								height="120px"
							/>
							<Skeleton
								width="100%"
								height="180px"
							/>
						</div>
					)
				}
				<Slider ref={refSlider} {...settings}>
					{
						prop.Review?.data?.results?.map((item) => (
							<div className="small" key={item.id}>
								<ArticleCard
									data={item}
									isMobile={prop.isMobile}
								/>
							</div>
						))
					}
				</Slider>
				<div className="navigator" style={{ textAlign: "center" }}>
					<button className="button-prev" type="button" onClick={() => handlePrev()}>
						<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`} alt="" />
					</button>
					<button className="button" type="button" onClick={() => handleNext()}>
						<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`} alt="" />
					</button>
				</div>
			</section>
			<div className="trapezoid" />

			<style jsx>
				{`
					.container::after {
						position: absolute;
						content: attr(data-title);
						z-index: -1;
						bottom: -16px;
						left: 0;
						width: -webkit-fill-available;
						border-top: 16px solid#a9b7d2;
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						height: 0;
					}
					.list-menu {
						display: flex;
						gap: 8px;
						margin: 10px 16px;
					}
					.menu {
						border: none;
						font-family: Roboto;
						font-size: 14px;
						font-weight: 400;
						line-height: 13px;
						letter-spacing: 0em;
						text-align: left;
						color: #637381;
						background: #FFFFFF;
						border-radius: 16px;
						padding: 8px 12px;
					}
					.active {
						border: none;
						font-family: Roboto;
						font-size: 14px;
						font-weight: 400;
						line-height: 13px;
						letter-spacing: 0em;
						text-align: left;
						color: #096DD9;
						background: #F0F3F7;
						border-radius: 16px;
						padding: 8px 12px;
					}
					.navigator {
						margin: -4px 0 0 0;
						// padding: 10px;
						display: flex;
						justify-content: space-between;
					}
					.button-prev {
						transform: rotateY(180deg);
						padding: 10px;
						border: none;
						background: transparent;
						z-index: 1;
					}
					.button {
						padding: 10px;
						border: none;
						background: transparent;
						z-index: 1;
					}

					.linkref {
						color: inherit;
						text-decoration: none;
						color: #fff;
					}
					.txt-link {
						font-family: Poppins;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: 0 16px;
						border-bottom: 1px solid #fff;
						padding: 8px;
						color: #fff;
						width: 95px;
					}
          .title {
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 48px;
						line-height: 42px;
						letter-spacing: 0em;
						text-align: left;
						color: #fff;
						position: relative;
						padding: 20px;
						margin: 10px;

						background-image: url(${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/splash.svg);
						background-repeat: no-repeat;
						background-position: 0px 25px;
          }
          a, a:hover, a:visited, a:active {
            color: inherit;
            text-decoration: none;
          }
          .list-article {
            margin: 16px 0 14px 0;
          }
          .container {
            padding: 30px 0 30px 0;
						margin: 30px 0 36px 0;
						background: #5875AC;
						position: relative;
          }
					.small {
						margin: 0 0 20px 0;
						
					}
          .article-update {
						background: #F0F3F7;
						border-radius: 4px;
						padding: 11px 12px;
						margin: 0 16px;
          }
        `}

			</style>
			<style>
				{`
				.slick-dots li {
					width: 8px;
				}
				`}
			</style>

		</div>
	);

};

export default mobile;
