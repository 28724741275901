import React, { useRef } from "react";
import Slider from "react-slick";
import ReviewCard from "Components/review_card";
import Skeleton from "Components/skeleton";

const desktop = ({ ...props }) => {

	const prop = props;
	const refSlider = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		arrows: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		className: "center",
		centerMode: true,
		centerPadding: "60px",
	};

	const handleNext = () => {

		refSlider.current.slickNext();

	};
	const handlePrev = () => {

		refSlider.current.slickPrev();

	};

	return (

		<div className="container" data-title="">
			<div className="child-container">
				<div className="article-menu">
					<p className="title">
						MD
						<br />
						REVIEW
					</p>
					<a href={prop.linkMore} className="linkref">
						<p className="txt-link">

							SEE ALL
							&nbsp;
							<img style={{ color: "#fff" }} src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-white.svg`} alt="ic-arrow-white" width="17" />
						</p>
					</a>
					<div className="list-menu">
						<button type="button" className={`menu ${prop.active === "product" ? "active" : ""}`} onClick={() => prop.handleReview("product")}>Product Review</button>
						<button type="button" className={`menu ${prop.active === "service" ? "active" : ""}`} onClick={() => prop.handleReview("service")}>Service Review</button>
					</div>

				</div>
				<section className="article-update">
					{
						!prop.Review?.data?.results
					&& (
						<div className="shimmer">
							{
								[0, 1, 3].map((item) => (
									<div className="loading" key={item}>
										<Skeleton
											width="100%"
											height="119px"
											style={{ margin: 0 }}
										/>
										<Skeleton
											width="100%"
											height="178px"
											style={{ margin: 0 }}

										/>
										<style>
											{`
												.article-update .loading{
													background-color: #F0F3F7;
													padding: 22px 16px;
													width: 379px;
													margin: 0;
													margin-left: 22px;
													border-radius: 4.928px;
												}
												.article-update .shimmer{
													display: flex;
													gap: 17px;
													overflow: hidden;
													margin-left: -22px;
												}
											`}
										</style>
									</div>
								))
							}
						</div>

					)
					}
					<Slider ref={refSlider} {...settings}>
						{
							prop.Review?.data?.results?.map((item) => (
								<div className="small" key={item.id}>
									<ReviewCard
										data={item}
										isMobile={prop.isMobile}
									/>
								</div>

							))
						}
					</Slider>
					<div className="navigator" style={{ textAlign: "center" }}>
						<button className="button-prev" type="button" onClick={() => handlePrev()}>
							<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-white.svg`} alt="" />
						</button>
						<button className="button" type="button" onClick={() => handleNext()}>
							<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-white.svg`} alt="" />
						</button>
					</div>
				</section>
				<div className="trapezoid" />
			</div>

			<style jsx>
				{`
					.article-menu{
						margin-left: -50px;
					}
					.child-container{
						max-width: 1280px;
						margin: 0 auto;
						display: flex;
					}
					.container::after {
						position: absolute;
						content: attr(data-title);
						z-index: -1;
						bottom: -16px;
						left: 0;
						width: -webkit-fill-available;
						border-top: 16px solid#a9b7d2;
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						height: 0;
					}
					.list-menu {
						display: flex;
						gap: 8px;
						margin: 10px 16px;
						justify-content: flex-end;
					}
					.active {
						border: none;
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 21px;
						display: flex;
						align-items: center;
						color: #E66983 !important;
						background: #FFFFFF !important;
						border-radius: 29.7776px;
						padding: 8px 19.8621px;
					}
					.menu{
						border: none;
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 21px;
						display: flex;
						align-items: center;
						color: #637381;
						background: #F0F3F7;
						border-radius:29.7776px;
						padding: 8px 19.8621px;
						cursor: pointer;
					}
					.navigator {
						margin: 40px 0 0 0;
						// padding: 10px;
						display: flex;
						justify-content: space-around;
					}
					.button-prev {
						transform: rotateY(180deg);
						padding: 10px;
						border: none;
						background: transparent;
						z-index: 1;
					}
					.button {
						padding: 10px;
						border: none;
						background: transparent;
						z-index: 1;
					}

					.linkref {
						color: inherit;
						text-decoration: none;
						color: #fff;
						display: flex;
						justify-content: flex-end;
						margin-bottom: 32px;
					}
					.txt-link {
						font-family: Poppins;
						font-size: 18px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: 0 16px;
						border-bottom: 1px solid#fff;
						padding: 8px;
						color: #fff;
						width: 95px;
					}
					.title {
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 88px;
						line-height: 89px;
						letter-spacing: 0em;
						text-align: left;
						color: #fff;
						position: relative;
						padding: 40px;
						margin: 10px;
			text-align: right;
						background-image: url(${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/splash2.svg);
						background-repeat: no-repeat;
						background-position: 2px 71px;
			padding-bottom: 22px;
		}
		a, a:hover, a:visited, a:active {
			color: inherit;
			text-decoration: none;
		}
		.list-article {
			margin: 16px 0 14px 0;
		}
		.container {
			padding: 110px 0 39px 0;
						margin: 30px auto 36px auto;
						background: #5875AC;
						position: relative;
		}
					.card-small {
						background: pink;
						padding: 20px;
					}
					.small {
						margin: 0 0 20px 0;
						
					}
		.article-update {
						//background: #F0F3F7;
						border-radius: 4px;
						padding: 11px 0 11px 12px;
						margin-left: 16px;
			//width: 100%;
		}
		`}

			</style>
			<style>
				{`
					.article-update .slick-dots {
						bottom: -70px;
					}
					.article-update .slick-dots li {
						width: 8px;
					}
					.article-update .slick-list {    
							width: 1020px;
							padding: 0 !important;
					}
					.article-update .slick-dots li button:before{
						color: #fff;
					}
					.article-update .slick-track {    
							display:flex;
							//gap:36px;
					}
				`}
			</style>

		</div>
	);

};

export default desktop;
