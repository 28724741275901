import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const HottestSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		type: prop.type || "DEFAULT",
		ArticlePopular: prop.ArticlePopular.data.data.map((item, idx) => ({
			id: idx,
			title: item.title,
			image: item.image,
			excerpt: item.excerpt,
			author: item.author,
			slug: item.slug,
			date: item.created_at,
		})),
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default HottestSection;
