import PropTypes from "prop-types";
import Shimmer from "Components/shimmer";

const ShimmerYoutubeVideos = ({ isMobile }) => {

	const device = isMobile ? "mobile" : "desktop";
	return (
		<div className={`shimmer-youtube shimmer-youtube--${device}`}>
			<div className={`shimmer-youtube__image-highlight shimmer-youtube__image-highlight--${device}`}>
				<Shimmer width="100%" height={isMobile ? "203px" : "414px"} />
				<div className={`shimmer-youtube__text-highlight shimmer-youtube__text-highlight--${device}`}>
					<Shimmer width="100%" height="12px" />
					<Shimmer width={isMobile ? "210px" : "420px"} height="12px" />
				</div>
			</div>

			<div className={`shimmer-youtube__video-list shimmer-youtube__video-list--${device}`}>
				<Shimmer width="204px" height="120px" />
				<Shimmer width="204px" height="120px" />
				<Shimmer width="204px" height="120px" />
			</div>

			<style jsx>
				{`
          .shimmer-youtube :global(.fdn-shimmer) {
            margin-bottom: var(--space-0);
          }

          .shimmer-youtube__image-highlight {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            max-width: 700px;
          }

          .shimmer-youtube__image-highlight--mobile {
            padding: var(--space-0) var(--space-16);
            box-sizing: border-box;
          }

          .shimmer-youtube__text-highlight {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            max-width: 700px;
          }

          .shimmer-youtube__video-list {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            margin-top: var(--space-32);
          }

          .shimmer-youtube__video-list--mobile {
            padding-left: var(--space-16);
            display: grid;
            grid-template-columns: 204px 204px 204px;
            overflow-x: auto;
            box-sizing: border-box;
            justify-content: flex-start;
          }
        `}
			</style>
		</div>
	);

};

ShimmerYoutubeVideos.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default ShimmerYoutubeVideos;
