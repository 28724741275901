import React from "react";
import ArticleCard from "Components/article_card";

const Desktop = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			<section className="container-latest-article">
				<div className="large-article">
					<ArticleCard
						isMobile={prop.isMobile}
						type="LARGE"
						title={prop.ArticleZone?.data?.data?.[0]?.title}
						excerpt={prop.ArticleZone?.data?.data?.[0]?.excerpt}
						category={
							prop.ArticleZone?.data?.data?.[0]?.category?.[0]?.name || null
						}
						catSlug={
							prop.ArticleZone?.data?.data?.[0]?.category?.[0]?.slug || null
						}
						image={prop.ArticleZone?.data?.data?.[0]?.image}
						author={prop.ArticleZone?.data?.data?.[0]?.author}
						slug={prop.ArticleZone?.data?.data?.[0]?.slug}
						date={prop.ArticleZone?.data?.data?.[0]?.created_at}
						isSponsore={prop.ArticleZone?.data?.data?.[0]?.category?.find((data) => data?.name?.toLowerCase() === "sponsored post")}
					/>
				</div>
				<div className="list-article">
					{prop.ArticleZone?.data?.data?.slice(1, 4).map((item) => (
						<div className="small" key={item?.id}>
							<ArticleCard
								isDate
								isImage
								isMobile={prop.isMobile}
								type="SMALL"
								title={item?.title}
								width="120px"
								height="120px"
								fontSize="20px"
								category={
									item?.category?.[0]?.name || null
								}
								catSlug={
									item?.category?.[0]?.slug || null
								}
								image={item?.image}
								author={item?.author}
								date={item?.created_at}
								slug={item?.slug}
							/>
						</div>
					))}
				</div>
			</section>
			<style jsx>
				{`					
					.large-article {
						flex-grow: 1;
					}

					.list-article {
						gap: 18px;
						flex-grow: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 100%;
					}

					.container {
						margin: 52px 115px 80px 115px;
					}

					.container-latest-article {
						padding: 0;
						max-width: 100%;
						gap: 56px;
						display: grid;
						grid-template-columns: 520px auto;
						// margin-top: 62px;

						max-width: 1280px;
						margin: 62px auto 0 auto;
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
