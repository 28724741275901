import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="image">
			<Shimmer width="274px" height="164px" />
		</div>
		<div className="description">
			<Shimmer width="30%" height="12px" />
			<Shimmer width="274px" height="53px" />
			<Shimmer width="40%" height="17px" />
		</div>
		<style jsx>
			{`
		.author{
			margin-top:  27px;
		}
      `}
		</style>

	</div>
);

const Desktop = () => (
	<div className="container">
		<div className="article-shimmer">
			<div className="title">
				<Shimmer width="332px" height="47px" />
			</div>
			<div className="article-section">
				<div className="article-big">
					<Shimmer width="666px" height="405px" />
					<Shimmer width="121px" height="20px" />
					<Shimmer width="666px" height="64px" />
					<Shimmer width="666px" height="48px" />
					<Shimmer width="666px" height="17px" />
				</div>
				<div className="article-small">
					{
						[0, 1, 2, 3].map((item) => (
							<Card key={item} />
						))
					}
				</div>
			</div>

			<div className="watch-more">
				<Shimmer width="294px" height="50px" />
			</div>
		</div>
		<style jsx>
			{`
				.container{
					display: flex;
					justify-content: center;
    				align-items: center;
    				margin-right: 80px;
				}
				.article-shimmer {
					margin: 40px 78px 30px 78px;
					max-width: 1280px;
				}
				.title{
					display: grid;
					margin-bottom: 30px;
					align-items: center;
					justify-items: start;
				}
				.article-section{
					display: flex;
					gap: 36px;
				}
				.article-small{
					display: grid;
					grid-template-columns: auto auto;
					width: 100%;
					column-gap: 40px;
				}
				.watch-more{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 32px;
				}
			`}

		</style>
	</div>
);

export default Desktop;
