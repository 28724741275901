import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TitleSection from "Components/title_section";
import ShimmerYoutubeVideos from "Components/shimmer/shimmer_youtube/shimmer_youtube_videos";
import ShimmerYoutubeArticles from "Components/shimmer/shimmer_youtube/shimmer_youtube_articles";
import YoutubeVideo from "./youtube_video";
import ArticleVideo from "./article_video";

const SectionYoutubeVideo = ({ isMobile }) => {

	const device = isMobile ? "mobile" : "desktop";

	const isLoadingDiscoverVideos = useSelector((state) => state.storeDiscoverVideo.status === "fetch_loading");
	const isLoadingYoutubeVideos = useSelector((state) => state.storeYoutubeVideo.status === "fetch_loading");

	return (
		<div className={`youtube-videos youtube-videos--${device}`}>
			<TitleSection
				title="Videos For You"
				color="#DB284E"
				isLine
				isMobile={isMobile}
			/>
			<div className={`youtube-videos__content youtube-videos__content--${device}`}>
				{
					isLoadingYoutubeVideos
						? <ShimmerYoutubeVideos isMobile={isMobile} />
						: <YoutubeVideo isMobile={isMobile} />
				}

				<div className={`youtube-videos__articles youtube-videos__articles--${device}`}>
					<TitleSection
						title="LATEST VIDEO ARTICLES"
						color="#DB284E"
						isLine
						isMobile
					/>

					<div className={`youtube-videos__articles-space youtube-videos__articles-space--${device}`} />

					{
						isLoadingDiscoverVideos
							? <ShimmerYoutubeArticles />
							: <ArticleVideo isMobile={isMobile} />
					}

				</div>
			</div>

			<style jsx>
				{`
					.youtube-videos {
						margin: var(--space-80) auto;
					}

					.youtube-videos--mobile {
						margin: var(--space-48) auto var(--space-56) auto;
					}
					
					.youtube-videos__content {
						display: grid;
						grid-template-columns: 700px 460px;
						width: 1210px;
						gap: 40px;
						margin: auto;
						padding: 38px var(--space-16) var(--space-0) var(--space-16);
						box-sizing: content-box;
					}

					.youtube-videos__content--mobile { 
						display: flex;
						flex-direction: column;
						gap: 24px;
						margin: var(--space-24) auto;
						padding: var(--space-0);
						width: 100%;
						box-sizing: border-box;
					}

					.youtube-videos__articles--mobile {
						padding: var(--space-0) var(--space-16);
					}

					.youtube-videos__articles--mobile :global(.title-text) {
						font-size: 16px;
					}

					.youtube-videos__articles-space {
						height: 28px;
					}

					.youtube-videos__articles-space--mobile {
						height: 16px;
					}
				`}
			</style>
		</div>
	);

};

SectionYoutubeVideo.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default SectionYoutubeVideo;
