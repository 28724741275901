import React from "react";
import ArticleCard from "Components/article_card";
import ShimmerPowerHour from "Components/shimmer/shimmer_power_hour";

const desktop = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			{
				!prop.ArticleCampaign?.data?.data?.data ? (
					<ShimmerPowerHour isMobile={prop.isMobile} />
				) : (
					<section className="article-campaign">
						<div className="title">
							<div className="title-section">
								<p className="title-text" data-title={prop.ArticleCampaign?.data?.data?.title_image}>
									{prop.ArticleCampaign?.data?.data?.title_image}
								</p>
							</div>
						</div>
						<div className="list-article-desktop">
							{
								prop.ArticleCampaign?.data?.data?.data?.map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											isImage
											type="SMALL"
											fontSize="20px"
											title={item?.title}
											category={
												item?.category?.[0]?.name || null
											}
											catSlug={
												item?.category?.[0]?.slug || null
											}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
											height={139}
											width={139}
										/>
									</div>
								))
							}
						</div>
					</section>
				)
			}
			<style jsx>
				{`

					.title-section {
						display: flex;
						align-items: center;
						margin-bottom: 15px 0;
						justify-content: center;
					}

					.title-text {
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 32px;
						line-height: 17px;
						display: flex;
						align-items: center;
						text-transform: capitalize;
						color: #000000;
						letter-spacing: 0em;
						text-align: left;
						margin: 0;
						position: relative;
					}
					.title-text::before {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -1;
						top: 4px;
						left: -4px;
						width: 103%;
						background: #e2b0b0;
					} 
					.title-text::after {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -2;
						top: 8px;
						left: -2px;
						width: 104%;
						background: #e8e8e8;
					} 
					.title {
							margin-bottom: 38px;
					}
					.list-article-desktop {
							margin: 16px 0 14px 0;
							display: grid;
							grid-template-columns: repeat(2, 1fr);
							column-gap: 5rem;
					}
					.small {
							padding: 16px 0;
					}
					.container {
							margin: 24px 0 30px 0;
							background-image: url(${prop.ArticleCampaign?.data?.data?.border_image});
							background-repeat: no-repeat;
							background-size: cover;
							padding: 40px 16px 30px 16px;
					}
					.article-campaign {
							padding: 54px 46px;
							background: #fff;
							position: sticky;
							max-width: 1280px;
							margin: 0 auto;

					}
			`}
			</style>
		</div>
	);

};

export default desktop;
