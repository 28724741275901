const IconZigzag = (props) => (
	<svg
		width="165"
		height="23"
		viewBox="0 0 165 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M58.2187 5.41375L63.5793 0L68.9604 5.41375L80.5 17.0885L75.1394 22.5022L63.5793 10.8275L57.4003 17.0885L52.0396 22.5022L46.679 17.0885L40.5 10.8275L34.321 17.0885L28.9604 22.5022L23.5997 17.0885L17.4207 10.8275L5.86061 22.5022L0.5 17.0885L12.0396 5.41375L17.4207 0L22.7813 5.41375L28.9604 11.654L35.1394 5.41375L40.5 0L45.8606 5.41375L52.0396 11.654L58.2187 5.41375Z"
			fill="#ECB966"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M106.781 17.0882L101.421 22.5019L96.0396 17.0882L84.5 5.41349L89.8606 -0.00026211L101.421 11.6744L107.6 5.41349L112.96 -0.00026009L118.321 5.4135L124.5 11.6744L130.679 5.4135L136.04 -0.000258073L141.4 5.4135L147.579 11.6744L159.139 -0.000256053L164.5 5.4135L152.96 17.0882L147.579 22.502L142.219 17.0882L136.04 10.8479L129.861 17.0882L124.5 22.5019L119.139 17.0882L112.96 10.8479L106.781 17.0882Z"
			fill="#ECB966"
		/>
	</svg>
);

export default IconZigzag;
