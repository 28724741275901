import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const ParentingSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		SingleArticle: prop.ArticleParenting?.data?.data?.[0] ? prop.ArticleParenting.data.data[0] : {},
		linkMore: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/parenting-mommies/`,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default ParentingSection;
