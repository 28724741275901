import React from "react";

const Shimmer = (props) => {

	const prop = props;
	return (
		<div className="fdn-shimmer">
			<style jsx>
				{`
          .fdn-shimmer {
            animation-name: wave;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            background-color: #f2f2f2;
            background-image: linear-gradient(
              125deg,
              #d8d8d8 20%,
              #f2f2f2 100%,
              #d8d8d8 50%
            );
            height: ${prop.height || "10px"};
            width: ${prop.width || "20%"};
            background-size: 50rem 10rem;
            margin-bottom: 10px;
            border-radius: ${prop.borderRadius || "4px"};
            box-sizing: border-box;
          }
          @keyframes wave {
            0% {
              background-position: -25rem 0;
            }
            100% {
              background-position: 25rem 0;
            }
          }
        `}
			</style>
		</div>
	);

};

export default Shimmer;
