import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="video">
			<Shimmer width="274px" height="164px" />
			<Shimmer width="65px" height="12px" />
			<Shimmer width="274px" height="60px" />
			<Shimmer width="274px" height="12px" />
		</div>
		<style jsx>
			{`
       
      `}
		</style>

	</div>
);

const Desktop = () => (
	<div className="container">
		<div className="article-shimmer">
			<div className="title">
				<Shimmer width="332px" height="47px" />
			</div>
			<div className="article-item">
				{
					[0, 1, 2, 3, 4].map((item) => (
						<Card key={item} />
					))
				}
			</div>
			<div className="watch-more">
				<Shimmer width="294px" height="50px" />
			</div>

		</div>
		<style jsx>
			{`
					.article-shimmer {
						
						margin: 40px auto 30px auto;
						max-width: 1280px;
					}
					.title{
						display: grid;
						margin-bottom: 30px;
						align-items: center;
						justify-items: start;
					}
					.article-item{
						display: flex;
						gap: 40px;
						overflow: hidden;
						margin: 62px auto 0 auto;
						padding: 0px 16px 0px 20px;
					}
					.watch-more{
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 32px;
					}
				`}

		</style>

	</div>
);

export default Desktop;
