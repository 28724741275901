import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import PropTypes from "prop-types";
import ImageFallback from "Components/image_fallback";

const SETTING_HIGHLIGHT = {
	dots: false,
	infinite: true,
	speed: 500,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 5000,
};

const YoutubeVideo = ({ isMobile }) => {

	const SETTING_LIST = {
		dots: false,
		infinite: true,
		speed: 500,
		arrows: false,
		slidesToShow: isMobile ? 1 : 3,
		variableWidth: isMobile,
		swipe: false,
	};

	const device = isMobile ? "mobile" : "desktop";
	const refSlider = useRef(null);
	const moreRef = useRef(null);
	const [currentHighlight, setCurrentHighlight] = useState(0);

	const youtubeVideos = useSelector((state) => state.storeYoutubeVideo.data);
	const sliderIndex = [...Array(youtubeVideos?.items?.length ?? 0).keys(), 0, 1];

	const onChangeHighlight = (oldSlide, currentSlide) => {

		// To find out current slide at the second slider
		const currentSlideMore = moreRef.current?.innerSlider?.state?.currentSlide ?? 0;

		// To find out how many indexes that shown in the slider
		const currentActiveSlides = isMobile
			? [currentSlideMore]
			: sliderIndex.slice(currentSlideMore, currentSlideMore + 3);

		if (!currentActiveSlides?.includes(currentSlide)) {

			// If diff equals to 1 means that current slide index is bigger than old slide index
			// also means that user click next, vice versa
			// If diff equals to negativeLastIndex means that old slide is the last item and
			// current slide index back to 0, vice versa

			const negativeLastIndex = ((youtubeVideos?.items?.length ?? 1) - 1) * -1;
			const diff = currentSlide - oldSlide;

			if ([1, negativeLastIndex].includes(diff)) moreRef.current?.slickNext?.();
			else moreRef.current?.slickPrev?.();

		}

		setCurrentHighlight(currentSlide);

	};

	const nextHighlight = () => {

		refSlider.current?.slickNext?.();

	};

	const prevHighlight = () => {

		refSlider.current?.slickPrev?.();

	};
	return (
		<div className={`youtube youtube--${device}`}>
			<div className={`youtube__highlight youtube__highlight--${device}`}>
				<Slider ref={refSlider} {...SETTING_HIGHLIGHT} beforeChange={onChangeHighlight}>
					{
						Array.isArray(youtubeVideos?.items) && youtubeVideos?.items?.map((video) => (
							<a key={video?.id?.videoId} href={`https://www.youtube.com/embed/${video?.id?.videoId}`} target="_blank" rel="noreferrer" style={{ width: 240 }}>
								<div className={`youtube__video-highlight youtube__video-highlight--${device}`}>
									<div className={`youtube__highlight-image youtube__highlight-image--${device}`}>
										<ImageFallback
											src={video?.id?.videoId ? `${process.env.NEXT_PUBLIC_YOUTUBE_IMAGE_URL}/vi/${video?.id?.videoId}/maxresdefault.jpg` : process.env.NEXT_PUBLIC_S3_IMAGE_EMPTY_2_1}
											alt={video?.snippet?.title}
											width={845}
											height={500}
											quality={80}
											fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
											objectFit="cover"
										/>

										<div className={`youtube__play youtube__play--${device}`}>
											<ImageFallback
												src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/youtube-play.png`}
												alt="play"
												width={isMobile ? 56 : 80}
												height={isMobile ? 56 : 80}
												quality={80}
												fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
											/>
										</div>
									</div>

									<p className={`youtube__video-title youtube__video-title--${device}`}>
										{video?.snippet?.title}
									</p>
								</div>
							</a>
						))
					}
				</Slider>

				{isMobile && (
					<>
						<button onClick={prevHighlight} type="button" className={`youtube__left-small youtube__left-small--${device}`}>
							<ImageFallback
								src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/square-arrow-left.png`}
								alt="play"
								width={32}
								height={32}
								quality={80}
								fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
							/>
						</button>

						<button onClick={nextHighlight} type="button" className={`youtube__right-small youtube__right-small--${device}`}>
							<ImageFallback
								src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/square-arrow-right.png`}
								alt="play"
								width={32}
								height={32}
								quality={80}
								fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
							/>
						</button>
					</>
				)}
			</div>
			<div className={`youtube__list-video youtube__list-video--${device}`}>
				<div className={`youtube__slider-list youtube__slider-list--${device}`}>
					<Slider ref={moreRef} {...SETTING_LIST}>
						{
							Array.isArray(youtubeVideos?.items) && youtubeVideos?.items?.map((video, index) => (
								<a key={video?.id?.videoId} href={`https://www.youtube.com/embed/${video?.id?.videoId}`} target="_blank" rel="noreferrer">
									<div className={`youtube__video-list-image youtube__video-list-image--${device} ${index === currentHighlight ? "youtube__video-list-image--active" : ""}`}>
										<ImageFallback
											src={video?.id?.videoId ? `${process.env.NEXT_PUBLIC_YOUTUBE_IMAGE_URL}/vi/${video?.id?.videoId}/maxresdefault.jpg` : process.env.NEXT_PUBLIC_S3_IMAGE_EMPTY_2_1}
											alt={video?.snippet?.title}
											width={408}
											height={240}
											quality={80}
											fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
											objectFit="cover"
										/>

										<div className={`youtube__play youtube__play--${device}`}>
											<ImageFallback
												src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/youtube-play.png`}
												alt="play"
												width={40}
												height={40}
												quality={80}
												fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
											/>
										</div>
									</div>
								</a>
							))
						}
					</Slider>

					{!isMobile && (
						<>
							<button onClick={prevHighlight} type="button" className={`youtube__left-small youtube__left-small--${device}`}>
								<ImageFallback
									src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/square-arrow-left.png`}
									alt="play"
									width={32}
									height={32}
									quality={80}
									fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
								/>
							</button>

							<button onClick={nextHighlight} type="button" className={`youtube__right-small youtube__right-small--${device}`}>
								<ImageFallback
									src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/dyn/100/images/square-arrow-right.png`}
									alt="play"
									width={32}
									height={32}
									quality={80}
									fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
								/>
							</button>
						</>
					)}
				</div>
			</div>

			<style jsx>
				{`
                    .youtube {
						display: flex;
						flex-direction: column;
						gap: 32px;
					}

					.youtube--mobile {
						gap: 24px;
					}

                    .youtube__video-highlight {
						display: flex;
						flex-direction: column;
						gap: 20px;
					}

					.youtube__video-highlight--mobile {
						gap: 12px;
						padding: var(--space-0) var(--space-16);
					}

					.youtube__highlight,
					.youtube__highlight-image {
						position: relative;
					}

					.youtube__video-title {
						color: var(--text-default);
						font-family: Poppins, sans-serif;
						font-size: 24px;
						font-style: normal;
						font-weight: 600;
						line-height: 32px;
						text-transform: capitalize;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						margin: var(--space-0);
					}

					.youtube__video-title--mobile {
						font-size: 16px;
						line-height: 22px;
					}

					.youtube {
						display: flex;
						flex-direction: column;
						gap: 32px;
					}

					.youtube--mobile {
						gap: 24px;
					}

					.youtube__list-video {
						position: relative;
					}

					.youtube__list-video--mobile {
						padding-left: var(--space-16);
					}

					.youtube__slider-list {
						max-width: 680px;
						margin: auto;
					}

					.youtube__video-list-image {
						width: 204px;
						height: 120px;
						margin: auto;
						position: relative;
						padding: 6px var(--space-4) 6px var(--space-0);
					}

					.youtube__video-list-image--mobile {
						margin-right: var(--space-16);
					}

					.youtube__video-list-image--active {
						padding: var(--space-4) var(--space-4) var(--space-4) var(--space-4);
						border: 2px solid var(--border-strong);
					}

					.youtube__play {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
						top: 0;
						left: 0;
						pointer-events: none;
					}

					.youtube__left-small {
						position: absolute;
						cursor: pointer;
						background-color: transparent;
						border: none;
						top: 48px;
  						left: 0;
					}

					.youtube__left-small--mobile {
						top: 35%;
						left: 4px;
					}

					.youtube__right-small {
						position: absolute;
						cursor: pointer;
						background-color: transparent;
						border: none;
						top: 48px;
  						right: 0;
					}

					.youtube__right-small--mobile {
						top: 35%;
						right: 4px;
					}
                `}
			</style>
		</div>
	);

};

YoutubeVideo.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default YoutubeVideo;
