/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

const Banner = ({ ...props }) => {

	const prop = props;

	const [Dataads, setDataads] = useState("");
	const [IsPLaceholder, setIsPLaceholder] = useState(true);

	const handleData = (e) => {

		setDataads(e);

		setIsPLaceholder(false);

	};

	useEffect(() => {

		const site = prop.isMobile ? "mobile" : "desktop";
		const urls = ["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
			? "https://detikads.femaledaily.com/static/ads/mommiesdaily/"
			:	"https://detikads.femaledaily.com/static/ads/mommiesdaily/devel_mommiesdaily/";
		fetch(`${urls + site}/${prop.name}.txt?${Math.random()}`)
			.then((response) => response.text())
			.then((data) => {

				data !== "" || data !== " " || data !== "  "	|| data !== "   " ? handleData(data)
					: null;

			})
			.catch((error) => {

				setIsPLaceholder(false);

				console.error(`onRejected data: ${error}`);

			});

	}, []);

	useEffect(() => {

		try {

			reviveAsync["0cceecb9cae9f51a31123c541910d59b"].refresh();

		} catch (e) {

			if (IsPLaceholder) {

				setIsPLaceholder(false);

			}

		}
		try {

			if (Dataads && Dataads !== " ") {

				refreshSlotDfp(prop.name);

			}

		} catch (e) {

			console.error("error refreshSlotDfp: ", e);

		}

	});
	return (
		<>
			{IsPLaceholder ? (
				<div id={prop.component_id || ""} className={prop.class_name || ""}>
					<img
						src={`https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/ads/${prop.placeholder}.png`}
						alt="banner-detik"
					/>
				</div>
			) : null}
			<div
				id={prop.component_id}
				className={prop.class_name || ""}
				dangerouslySetInnerHTML={{
					__html: Dataads,
				}}
			/>
			<style jsx>
				{`
					.ads-mediumrectangle1 {
						display: flex;
						justify-content: center;
					}
					.ads-mediumrectangle2-detail {
						display: flex;
						justify-content: center;
						margin: 0 0 24px 0;
					}
					.ads-mediumrectangle3 {
						display: flex;
						justify-content: center;
					}
          .bottomframe {
            bottom: -4px;
            left: 0px;
            position: fixed;
            right: 0px;
            text-align: center;
            z-index: 3;
          }
          .ads-billboard {
						text-align: center;
						margin: 0 0 16px 0;
          }
					.billboard-home {
						margin: 66px 0 0 0;
						text-align: center;
					}
					.horizontal-home {
						margin: 16px 0 20px 0;
					}
					.banner-1-mobile {
						text-align: center;
					}
					.mobile-staticbanner2{
						margin-top: 16px;
					}
					.ads-static-3 {
						text-align: center;
						margin: 0;
					}
        `}
			</style>
		</>
	);

};

export default Banner;
