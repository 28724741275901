import Shimmer from "Components/shimmer";

const ShimmerYoutubeArticles = () => (
	<div className="shimmer-youtube-articles">
		{[...Array(4).keys()].map((key) => (
			<div key={key} className="shimmer-youtube-articles__container">
				<Shimmer width="94px" height="94px" />
				<div className="shimmer-youtube-articles__text">
					<Shimmer width="61px" height="12px" />
					<Shimmer width="100%" height="12px" />
					<Shimmer width="100%" height="12px" />

				</div>
			</div>
		))}
		<style jsx>
			{`
                .shimmer-youtube-articles {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                }

                .shimmer-youtube-articles :global(.fdn-shimmer) {
                    margin-bottom: var(--space-0);
                }

                .shimmer-youtube-articles__container {
                    display: grid;
                    grid-template-columns: 94px 1fr;
                    gap: 12px;
                }

                .shimmer-youtube-articles__text {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 12px;
                }
            `}
		</style>
	</div>
);

export default ShimmerYoutubeArticles;
