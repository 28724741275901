import React from "react";
import ArticleCard from "Components/article_card";
import ShimmerPowerHour from "Components/shimmer/shimmer_power_hour";

const mobile = ({ ...props }) => {

	const prop = props;
	return (
		<div className="container">
			{
				!prop.ArticleCampaign?.data?.data?.data ? (
					<ShimmerPowerHour isMobile={prop.isMobile} />
				) : (
					<section className="article-update">
						<div className="title">
							<div className="title-section">
								<p className="title-text" data-title={prop.ArticleCampaign?.data?.data?.title_image}>
									{prop.ArticleCampaign?.data?.data?.title_image}
								</p>
							</div>
						</div>
						<div className="list-article">
							{

								prop.ArticleCampaign?.data?.data?.data?.map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											type="SMALL"
											title={item?.title}
											category={
												item?.category?.[0]?.name || null
											}
											catSlug={
												item?.category?.[0]?.slug || null
											}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
										/>
									</div>
								))
							}
						</div>

					</section>
				)
			}
			<style jsx>
				{`

					.title-section {
						display: flex;
						align-items: center;
						margin: 15px 0;
						justify-content: center;
					}

					.title-text {
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 24px;
						line-height: 12px;
						letter-spacing: 0em;
						text-align: left;
						margin: 0;
						position: relative;
						color: #000;
					}
					.title-text::before {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -1;
						top: 4px;
						left: 0px;
						width: 102%;
						background: #E2B0B0;
					} 
					.title-text::after {
						position: absolute;
						content: attr(data-title);
						color: transparent;
						z-index: -2;
						top: 8px;
						left: 2px;
						width: 102%;
						background: #E8E8E8;
					} 
          .title {
            margin: 30px 0;
          }
          .list-article {
            margin: 16px 0 14px 0;
          }
          .small {
            padding: 16px 0;
          }
          .container {
            margin: 24px 0 30px 0;
						background-image: url(${prop.ArticleCampaign?.data?.data?.border_image});
						background-repeat: no-repeat;
						background-size: cover;
						padding: 40px 16px 30px 16px;
          }
          .article-update {
						padding: 40px 11px 10px 11px;
						background: #fff;
						position: sticky;
          }
        `}

			</style>

		</div>
	);

};

export default mobile;
