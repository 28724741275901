import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const RelationSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		SingleArticle: prop.ArticleRelation?.data?.data?.[0] ? prop.ArticleRelation.data.data[0] : {},
		linkMore: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/relationship/`,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default RelationSection;
