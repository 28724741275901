import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="left">
			<Shimmer width="94px" height="94px" />
		</div>
		<div className="right">
			<Shimmer width="30%" height="14px" />
			<Shimmer width="100%" height="28px" />
			<Shimmer width="40%" height="14px" />
		</div>
		<style jsx>
			{`
        .left {
          flex-shrink: 0;
        }
		.right{
			width: 70%;
		}
        .card {
          display: flex;
          gap: 20px;
        }
      `}
		</style>

	</div>
);

const Mobile = () => (
	<div className="article-shimmer">
		<div className="title">
			<Shimmer width="200px" height="32px" />
		</div>
		<div className="article-item">
			{
				[0, 1, 2, 3, 4].map((item) => (
					<Card key={item} />
				))
			}
		</div>

		<style jsx>
			{`
				.article-shimmer {
					margin: 40px 16px 30px 16px;
				}
				.title{
					display: grid;
					margin-bottom: 40px;
					align-items: center;
					justify-items: center;
				}
			`}

		</style>
	</div>
);

export default Mobile;
