import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";

const mobile = ({ ...props }) => {

	const prop = props;

	return (
		<div className="detik-container-desktop">
			<div className="title">
				<TitleSection
					title="Our Sister’s Company"
					color="#D42428"
					isLine
				/>
			</div>
			<section className="article-detik">
				<div className="list-article">
					{
						prop.ArticleDetik.map((item) => (
							<div className="item-card" key={item.category}>
								<ArticleCard
									type="DETIK"
									title={item.title}
									image={item.image}
									category={item.category}
									extraLink={item.link}
								/>
							</div>
						))
					}
				</div>

			</section>
			<style jsx>
				{`
          .title {
            margin: 30px 0;
          }
          .list-article {
						display: flex;
						gap: 36px;
          }
          .detik-container-desktop {
            margin: 80px 0;
          }

          .article-detik {
						display: flex;
						justify-content: center;
						margin: 50px auto;
						max-width: 1280px;

          }
        `}

			</style>

		</div>
	);

};

export default mobile;
