import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const ReviewCard = (props) => {

	const prop = props;

	const data = {
		...prop,
		linkProduct: prop.data?.post?.slug ? `${process.env.NEXT_PUBLIC_MD_REVIEW}/detail/${prop.data.post.slug}` : "",
		linkBrand: prop.data?.post?.product?.brand?.brand_slug ? `${process.env.NEXT_PUBLIC_MD_REVIEW}/brand/${prop.data?.post?.product.brand.brand_slug}` : "",
		linkUser: `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/md/${prop.data.user.username}`,
	};
	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default ReviewCard;
