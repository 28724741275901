/* eslint-disable react/jsx-indent */
import React from "react";
import Banner from "Components/banner/banner";
import env from "Consts/env";
import IconZigzag from "Components/icons/zigzag";
import { arrayCheck } from "Helpers/utils";
import { useSelector } from "react-redux";
import SectionHottest from "./section/section_hottest";
import SectionMostUpdate from "./section/section_most_update";
import SectionDetik from "./section/section_detik";
import SectionPowerHour from "./section/section_power_hour";
import SectionParenting from "./section/section_parenting";
import SectionEducation from "./section/section_education";
import SectionRelation from "./section/section_relation";
import SectionQuiz from "./section/section_quiz";
import SectionZone from "./section/section_zone";
import SectionReview from "./section/section_review";
import SectionWhatsOnFd from "./section/section_whats_on_fd";
import SectionMidlife from "./section/section_midlife";
import SectionYoutubeVideo from "./section/section_youtube_video";

const Mobile = ({ ...props }) => {

	const prop = props;

	const discoverVideos = useSelector((state) => state.storeDiscoverVideo);
	const youtubeVideos = useSelector((state) => state.storeYoutubeVideo);
	const articleMidlife = useSelector((state) => state.storeArticleMidlife);

	return (
		<div className="page-container">
			<h1 className="desc">
				A friendly non-judgemental place to share about parenting,
				education, and equality.
			</h1>
			{prop.MenuNavbar?.data?.data?.length
			&& prop.MenuNavbar?.data?.meta?.code === 200 ? (
					<div className="menus">
						{prop.MenuNavbar?.data?.data.map((item) => (
							<a
								className="item-menu"
								href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/${item.slug}/`}
								key={item.post_id}
							>
								{item.title}
							</a>
						))}
					</div>
				) : null}

			<Banner
				component_id="billboard"
				placeholder="staticbanner1"
				name="billboard"
				isMobile={prop.isMobile}
				class_name="ads-billboard"
			/>

			{prop.ArticleZone?.data?.meta?.code === 200 && (
				<SectionZone ArticleZone={prop.ArticleZone} isMobile={prop.isMobile} />
			)}
			{prop.ArticlePopular?.data?.meta?.code === 200
			&& Array.isArray(prop.ArticlePopular?.data?.data)
			&& prop.ArticlePopular?.data?.data.length > 0
			&& (
				<SectionHottest ArticlePopular={prop.ArticlePopular} isMobile={prop.isMobile} />
			)}
			{prop.ArticleMost?.data?.meta?.code === 200 && (
				<SectionMostUpdate isMobile={prop.isMobile} ArticleMost={prop.ArticleMost} />
			)}

			{prop.Review && prop.Review?.status !== "fetch_error" && (
				<SectionReview
					Review={prop.Review}
					handleReview={prop.handleReview}
					isMobile={prop.isMobile}
					active={prop.active}
				/>
			)}
			<Banner
				name="staticbanner1"
				placeholder="staticbanner2"
				isMobile={prop.isMobile}
				class_name="ads-static-3"
			/>
			{prop.ArticleCampaign && prop.ArticleCampaign?.status !== "fetch_error" && (
				<SectionPowerHour ArticleCampaign={prop.ArticleCampaign} isMobile={prop.isMobile} />
			)}

			{discoverVideos?.status !== "fetch_error" && youtubeVideos?.status !== "fetch_error" && (
				<SectionYoutubeVideo isMobile />
			)}

			<Banner
				name="staticbanner2"
				placeholder="staticbanner2"
				isMobile={prop.isMobile}
				class_name="ads-static-3"
			/>

			{prop.ArticleParenting && prop.ArticleParenting?.status !== "fetch_error" && (
				<SectionParenting ArticleParenting={prop.ArticleParenting} isMobile={prop.isMobile} />
			)}

			{prop.ArticleEducation && prop.ArticleEducation?.status !== "fetch_error" && (
				<SectionEducation ArticleEducation={prop.ArticleEducation} isMobile={prop.isMobile} />
			)}

			{arrayCheck(articleMidlife?.data?.data) && (
				<SectionMidlife isMobile articles={articleMidlife?.data?.data} />
			)}

			{prop.ArticleRelation && prop.ArticleRelation?.status !== "fetch_error" && (
				<SectionRelation ArticleRelation={prop.ArticleRelation} isMobile={prop.isMobile} />
			)}

			{prop.Quiz && prop.Quiz?.status !== "fetch_error" && (
				<SectionQuiz Quiz={prop.Quiz} isMobile={prop.isMobile} />
			)}

			<Banner
				component_id="staticbanner3"
				placeholder="staticbanner1"
				name="staticbanner3"
				isMobile={prop.isMobile}
				class_name="ads-static-3"
			/>
			{prop.ArticleDetik && prop.ArticleDetik.length ? (
				<SectionDetik ArticleDetik={prop.ArticleDetik} isMobile={prop.isMobile} />
			) : null}

			{prop.youtubeMeta && (
				<>
					<div className="homepage__divider-zigzag">
						<IconZigzag />
					</div>
					<SectionWhatsOnFd
						isMobile
						youtubeMeta={prop.youtubeMeta}
						buttonLink={env.WHATS_ON_FD_YOUTUBE_LINK}
						margin="0px var(--space-20) 51px var(--space-20)"
					/>
				</>
			)}

			<style jsx>
				{`
					.desc {
						font-family: Roboto;
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: left;
						margin: 16px;
					}
					hr.hr1 {
						border-top: 0.5px solid #dfe3e8;
					}
					.page-container {
						max-width: 480px;
						margin: 0 auto;
					}
					.item-menu {
						font-family: Roboto;
						font-size: 16px;
						font-weight: 700;
						line-height: 12px;
						letter-spacing: 0em;
						text-align: left;
						color: #000;
						flex-shrink: 0;
						padding: 16px 0;
					}
					.menus {
						border-top: 1px solid #e0e0e0;
						display: flex;
						gap: 28px;
						overflow: scroll;
						margin: 16px;

						-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
					}

					.menus::-webkit-scrollbar {
						display: none;
					}

					.homepage__divider-zigzag {
						display: flex;
						align-items: center;
						justify-content: center;
						margin: var(--space-24) auto;
						padding: var(--space-20) 0px;
					}
				`}
			</style>
		</div>
	);

};

export default Mobile;
