import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";

const mobile = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			<div className="title">
				<TitleSection
					title="Our Sister’s Company"
					color="#D42428"
					isLine
					isMobile={prop.isMobile}
				/>
			</div>
			<section className="article-update">
				<div className="list-article">
					{

						prop.ArticleDetik.map((item) => (
							<div className="small" key={item.category}>
								<ArticleCard
									isMobile={prop.isMobile}
									type="DETIK"
									title={item.title}
									image={item.image}
									category={item.category}
									extraLink={item.link}
								/>
							</div>
						))
					}
				</div>

			</section>
			<style jsx>
				{`
          .title {
            margin: 30px 0;
          }
          .list-article {
            margin: 16px 0 14px 0;
          }
          .small {
            border-top: 0.5px solid #e0e0e0;
            padding: 16px 0;
          }
          .container {
            margin: 32px 0 30px 0;
          }

          .article-update {
            padding: 0 16px;
          }
        `}

			</style>

		</div>
	);

};

export default mobile;
