/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import TitleSection from "Components/title_section";
import Image from "next/image";
import Vars from "Consts/vars";
import { momentDateUTC } from "Helpers/utils";

const Desktop = ({ ...props }) => {

	const prop = props;

	const [newDataArticle, setNewDataArticle] = useState([]);

	const handleClick = (index) => {

		const selectedElement = newDataArticle[index];
		const temporaryData = [...newDataArticle];
		temporaryData.splice(index, 1);
		const temporaryDataSort = temporaryData.sort((a, b) => (a.id > b.id ? 1 : -1));
		temporaryDataSort.unshift(selectedElement);

		setNewDataArticle(temporaryDataSort);

	};

	useEffect(() => {

		if (prop.ArticlePopular) {

			const newData = prop.ArticlePopular?.map((item) => (
				{
					...item,
					color: Vars.COLOR_TAB[item.id],
					number: item.id + 1,
				})) ?? [];

			setNewDataArticle(newData);

		}

	}, [prop.ArticlePopular]);

	return (
		<div className="tab">

			<div className="child-tab">
				{
					newDataArticle?.filter((data, index) => index < 4).map((item, index) => {

						if (index === 0) {

							return (

								<div
									className="title-container"
									style={{
										backgroundColor: `${item?.color?.color}`,
										padding: "35px 16px 20px 0",
										width: "95%",
										boxShadow: "rgb(94 113 141 / 46%) 6px 0px 8px -1px",
										zIndex: 2,
									}}
									key={item.number}
								>
									<TitleSection
										title="Most Read This Week"
										color="#D42428"
										colorFont="#fff"
										isLine
									/>
									<div className="container">
										<div className="article-card">
											<a href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${momentDateUTC(item.date)}/${item.slug}/` || "https://mommiesdaily.com/"} className="article-image-container">
												<Image
													src={item.image}
													alt="article"
													layout="fixed"
													objectFit="cover"
													// priority
													width={368}
													height={376}
													quality={70}
												/>
											</a>
											<div className="article-description">
												<a
													href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${momentDateUTC(item.date)}/${item.slug}/` || "https://mommiesdaily.com/"}
													className="title-article"
												>
													{item.title || "?Title?"}

												</a>
												<p className="excerpt">
													{item.excerpt}
												</p>
												<div className="author">
													<p href={item.author?.username ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/${item.author.username}` : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/mommiesdaily`} className="author-name">
														By
														&nbsp;
														<a href={item.author?.username ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/${item.author.username}` : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/mommiesdaily`}>
															{ item.author?.name ? item.author.name : "?author?"}
														</a>
													</p>
												</div>

											</div>
										</div>

									</div>
									<p className="number">
										{item.number}
									</p>
								</div>
							);

						}
						return (
							// eslint-disable-next-line jsx-a11y/no-static-element-interactions
							<div
								style={{ backgroundColor: `${item?.color?.color}`, width: 89 }}
								onClick={() => handleClick(index)}
								className="button-tab"
								key={item.number}
							>
								{item.number}
							</div>
						);

					})
				}

			</div>
			<style jsx>
				{`

                    .child-tab{
                        display:flex;
                    }
					.container{
						max-width: 1010px;
						margin:auto;
					}
                    .article-card{
                        display: flex;
                        gap: 44px;
                        justify-content: flex-start;
                       // margin-left:120px;
                        margin-top: 68px;
                    }
                    .title-article{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 42.325px;
                        line-height: 53px;
                        text-transform: capitalize;
                        color: #FFFFFF;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
                    }
                    .excerpt{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #FFFFFF;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                    .author{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        flex: none;
                        order: 2;
                        flex-grow: 0;
                    }
                    .author-name a{
                        text-transform: uppercase;
                        color: #fff;
                    }
                    .button-tab{
                        justify-content: center;
                        padding-bottom: 20px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 60px;
                        line-height: 99px;
                        display: flex;
                        align-items: flex-end;
                        text-transform: capitalize;
                        color: #FFFFFF;
												cursor: pointer;
                    }
                    .number{
                        justify-content: flex-end;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 60px;
                        line-height: 99px;
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        color: #FFFFFF;
                        margin: 0;
                    }
                    .article-description{
                        margin-right: 78px;
                    }
                `}
			</style>
		</div>
	);

};

export default Desktop;
