import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="left">
			<Shimmer width="188px" height="130px" />
		</div>
		<div className="right">
			<Shimmer width="50px" height="12px" />
			<Shimmer width="188px" height="36px" />
		</div>
	</div>
);

const Mobile = () => (
	<div className="article-shimmer">
		<div className="title">
			<Shimmer width="200px" height="32px" />
		</div>
		<div className="article-item">
			{
				[0, 1].map((item) => (
					<Card key={item} />
				))
			}
		</div>
		<div className="watch-more">
			<Shimmer width="200px" height="50px" />
		</div>

		<style jsx>
			{`
				.article-shimmer {
					margin: 40px 0px 30px 16px;
				}
				.title{
					display: grid;
					margin-bottom: 30px;
					align-items: center;
    				justify-items: start;
				}
				.article-item{
					display: flex;
    				gap: 20px;
					overflow: hidden;
				}
				.watch-more{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 32px;
				}
			`}

		</style>
	</div>
);

export default Mobile;
