/* eslint-disable max-len */
import moment from "moment";
import Axios from "axios";

export const setAjaxWpvq = () => ({
	__html: `
	  /* <![CDATA[ */
		var ajaxurl = "/api/sendquiz";
		var wpvq_imgdir = "${
	["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
		? "https://mommiesdaily.com/wp-content/plugins/wp-viral-quiz/views/img/"
		: "http://mommiesdaily.net/wp-content/plugins/wp-viral-quiz/views/img/"
	}";
		var wpvq_i18n_loading_label = "Loading";
		/* ]]> */

	  `,
});

export const setGoogleTags = () => ({
	__html: `
		 /* <![CDATA[ */
		var wpvq_dont_use_FBAPI = "false";
		var wpvq_API_already_loaded = "true";
		var wpvq_facebookAppID = "";
		var wpvq_forceFacebookShare = "false";
		/* ]]> */
  
		`,
});

export function ScrollToUp() {

	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});

}

export const setGTMHeader = () => {

	const GTM = process.env.NEXT_PUBLIC_GTM_ID;

	return {
		__html: `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${GTM}');
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${GTM}');
			`,
	};

};

export const setGTMHeader2 = () => {

	const GTM2 = process.env.NEXT_PUBLIC_GTM_ID2;

	return {
		__html: `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${GTM2}');
			`,
	};

};

export const setGTMBody = () => {

	const GTM = process.env.NEXT_PUBLIC_GTM_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
			`,
	};

};

export const setGTMBody2 = () => {

	const GTM2 = process.env.NEXT_PUBLIC_GTM_ID2;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM2}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
			`,
	};

};

// export const comScore = () => ({
// 	__html: `
// 		var _comscore = _comscore || [];
// 		_comscore.push({ c1: "2", c2: "8443234" });
// 		(function() {
// 			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
// 			s.src = "https://sb.scorecardresearch.com/cs/8443234/beacon.js";
// 			el.parentNode.insertBefore(s, el);
// 		})();
// 		`,
// });

export const setGoogleAnalytics = () => {

	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;
	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		var _gaq = _gaq || [];
		_gaq.push(['_setAccount', '${GaHost}']);
		_gaq.push(['_trackPageview']);

		_gaq.push(['fdn._setAccount', '${GaGeneral}']);
		_gaq.push(['fdn._setAllowLinker', true]);
		_gaq.push(['fdn._trackPageview']);

		(function() {
		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		ga.src = ('https:' == document.location.protocol ? '//ssl' : '//www') + '.google-analytics.com/ga.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
		})();
		`,
	};

};

export const alexa = () => ({
	__html: `
		_atrk_opts = { atrk_acct:"5TYNs1O7kI20L7", domain:"femaledaily.com",dynamic: true};
		(function() { var as = document.createElement('script'); as.type = 'text/javascript'; as.async = true; as.src = "//certify-js.alexametrics.com/atrk.js"; var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(as, s); })();

		`,
});

export const setFBPixel = () => ({
	__html: `
		!function(f,b,e,v,n,t,s)
		{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)}(window,document,'script',
		'https://connect.facebook.net/en_US/fbevents.js');
		fbq('init', '2799608803608945');
		fbq('track', 'PageView');
		`,
});

export const alexaNoScript = () => ({
	__html: `
		<img src="//certify.alexametrics.com/atrk.gif?account=5TYNs1O7kI20L7" style="display:none" height="1" width="1" alt="" />
		`,
});

// export const comScoreNoScript = () => ({
// 	__html: `
// 		<img src="https://sb.scorecardresearch.com/p?c1=2&amp;c2=8443234&amp;cv=3.6.0&amp;cj=1"></img>
// 		`,
// });

export const setFBPixelsNoscript = () => ({
	__html: `
		<img height="1" width="1"
		src="https://www.facebook.com/tr?id=2799608803608945&ev=PageView
		&noscript=1"/>
		`,
});

export const setGoogleAnalyticsNoscript = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaGeneral}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setGoogleAnalyticsNoscriptGaHost = () => {

	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaHost}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const momentDate = ({ date }) => {

	const localDate = moment.utc(date)
		.format("YYYY-MM-DD HH:mm:ss");

	const res = moment(localDate).add(7, "days").isBefore(moment())
		? moment(localDate).format("DD MMM YYYY")
		: moment(localDate).fromNow();
	return res;

};

export const momentDateUTC = (date) => {

	const localDate = moment.utc(date)
		.format("YYYY-MM-DD HH:mm:ss");

	const res = moment(localDate).format("YYYY/MM/DD");
	return res;

};

export const momentDateUser = ({ date }) => {

	const localDate = moment(date)
		.format("YYYY-MM-DD HH:mm:ss");
	const res = moment(localDate).add(7, "days").isBefore(moment())
		? moment(localDate).format("LL")
		: moment(localDate).fromNow();
	return res;

};

export const momentDateSynergy = ({ date, type }) => {

	const localDate = moment(date);
	const res = type === "mobile" ? moment(localDate).format("DD MMMM YYYY") : moment(localDate).format("DD MMM");
	return res;

};

export const Fetching = async (params) => {

	try {

		const result = await Axios({
			method: params.method,
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/${params.url}/`,
			data: params.data,
		});
		return result;

	} catch (error) {

		throw new Error("Error response");

	}

};

export async function adsDetik(params, isMobile) {

	try {

		const site = isMobile ? "mobile" : "desktop";
		const urls = ["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
			? "https://detikads.femaledaily.com/static/ads/mommiesdaily/"
			:	"https://detikads.femaledaily.com/static/ads/mommiesdaily/devel_mommiesdaily/";

		const res = await fetch(`${urls + site}/${params}.txt`);

		if (res.status > 300 ) {
			return ""
		}

		return await res.text();

	} catch (error) {

		process.exit(1);

	}

	return null;

}

export const injectAltImage = (query, value) => {

	const btnClosetop = document.querySelector(`.${query}`);
	if (btnClosetop) {

		return btnClosetop.setAttribute("alt", value);

	}
	return null;

	// btnClosetop ? btnClosetop.setAttribute("alt", value) : null;

};

export const checkHTTP = (link) => {

	if (!link.includes("http")) {

		return `https:${link}`;

	}

	return link;

};

export const setGTMupdated = () => ({
	__html: `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', ${process.env.NEXT_PUBLIC_GA_HOST_ID});
		`,
});

export const Rupiah = (props) => {

	const reverse = props.toString().split("").reverse().join("");
	const ribuan = reverse.match(/\d{1,3}/g);
	const final = ribuan.join(".").split("").reverse().join("");

	return `Rp. ${final}`;

};

export const ThousandSeparator = (props) => {

	const reverse = props?.toString().split("").reverse().join("");
	const ribuan = reverse.match(/\d{1,3}/g);
	const final = ribuan?.join(".").split("").reverse().join("");

	return final;

};

export const IdrConvert = (props) => {

	if (String(props).includes(".")) {

		const rp = Number(props.toString().replace(/[^0-9.-]+/g, ""));
		return Intl.NumberFormat("id-ID", {
			style: "currency",
			currency: "IDR",
		}).format(rp);

	}

	return Rupiah(props);

};

export const arrayCheck = (data) => Array.isArray(data) && data.length > 0;
