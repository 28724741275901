import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";

const Desktop = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			<div className="title">
				<TitleSection isMobile={prop.isMobile} title="Latest Article" isLine />
			</div>
			<section className="container-latest-article">
				<div className="large-article">
					<ArticleCard
						isMobile={prop.isMobile}
						type="LARGE"
						title={prop.SingleArticle?.title}
						excerpt={prop.SingleArticle?.excerpt}
						category={
							prop.SingleArticle?.category?.[0]?.name || null
						}
						catSlug={
							prop.SingleArticle?.category?.[0]?.slug || null
						}
						image={prop.SingleArticle?.image}
						author={prop.SingleArticle?.author}
						slug={prop.SingleArticle?.slug}
						date={prop.SingleArticle?.created_at}
					/>
				</div>
				<div className="list-article">
					{prop.ArticleMost?.data?.data?.slice(1, 5).map((item) => (
						<div className="small" key={item?.id}>
							<ArticleCard
								isDate
								isImage
								isMobile={prop.isMobile}
								type="SMALL"
								title={item?.title}
								width="139px"
								height="139px"
								fontSize="20px"
								category={
									item?.category?.[0]?.name || null
								}
								catSlug={
									item?.category?.[0]?.slug || null
								}
								image={item?.image}
								author={item?.author}
								date={item?.created_at}
								slug={item?.slug}
							/>
						</div>
					))}
				</div>
			</section>
			<div className="container-button">
				<a href={prop.linkMore} className="link">
					<p className="link-more">READ MORE</p>
				</a>
			</div>
			<style jsx>
				{`
					.title {
						margin: 30px 0;
					}

					a,
					a:hover,
					a:visited,
					a:active {
						color: inherit;
						text-decoration: none;
					}
					
					.container-button {
						display: flex;
						justify-content: center;
						margin: 48px 0;
					}

					.link-more {
						font-family: Poppins;
						font-size: 18px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;

						width: 130px;
						border: 1px solid #000;
						padding: 14px 83px;
						text-align: center;
						margin: 0;
					}

					.large-article {
						flex-grow: 1;
					}

					.list-article {
						gap: 18px;
						flex-grow: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 100%;
					}

					.container {
						margin: 52px 0 30px 0;
					}

					.container-latest-article {
						padding: 0 80px;
						max-width: 100%;
						gap: 56px;
						display: grid;
						grid-template-columns: 666px auto;
						// margin-top: 62px;

						max-width: 1280px;
						margin: 62px auto 0 auto;
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
