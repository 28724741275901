import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="image">
			<Shimmer width="164px" height="126px" />
		</div>
		<div className="description">
			<Shimmer width="30%" height="12px" />
			<Shimmer width="164px" height="54px" />
			<Shimmer width="40%" height="17px" />
		</div>
		<style jsx>
			{`
		.author{
			margin-top:  27px;
		}
      `}
		</style>

	</div>
);

const Mobile = () => (
	<div className="container">
		<div className="article-shimmer">
			<div className="title">
				<Shimmer width="202px" height="32px" />
			</div>
			<div className="article-section">
				<div className="article-big">
					<Shimmer width="343px" height="258px" />
					<Shimmer width="121px" height="12px" />
					<Shimmer width="343px" height="44px" />
					<Shimmer width="343px" height="17px" />
				</div>
				<div className="article-small">
					{
						[0, 1, 2, 3].map((item) => (
							<Card key={item} />
						))
					}
				</div>
			</div>

			<div className="watch-more">
				<Shimmer width="294px" height="50px" />
			</div>
		</div>
		<style jsx>
			{`
				.container{
					padding: 16px;
				}
				.title{
					margin-bottom: 35px;
				}
				.article-big{
					margin-bottom: 30px;
				}
				.article-small{
					display: grid;
					grid-template-columns: auto auto;
					justify-content: space-between;
				}
				.watch-more{
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 50px;
				}
			`}

		</style>
	</div>
);

export default Mobile;
