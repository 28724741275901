import RatingStar from "@fdn/rating-star";
import Image from "next/image";

const ReviewCard = ({ ...props }) => {

	const prop = props;

	return (
		<div className="review">
			<div className="product-section">
				<a className="image-container" href={prop.linkProduct} aria-label="link">
					<Image
						src={prop.data?.post?.product
							? prop.data?.post?.product?.image?.[0]?.image
							: prop.data?.post?.service?.image?.[0]?.image}
						width={100}
						height={100}
						quality={70}
						layout="fixed"
						objectFit="cover"
					/>
				</a>
				<div className="product-desc">
					{

						prop.data?.post?.product
							? 	(
								<>
									<p className="prod-brand">
										<a href={prop.linkBrand}>
											{prop.data?.post?.product?.brand?.brand_name}
										</a>
									</p>
									<p className="prod-name">
										<a href={prop.linkProduct}>
											{prop.data?.post?.name}
										</a>
									</p>
								</>
							)
							:							(
								<>
									<p className="prod-brand">
										<a href={prop.linkProduct}>
											{prop.data?.post?.name}
										</a>
									</p>
									<p className="prod-name">{prop.data?.post?.service?.location}</p>
								</>
							)
					}

					<p className="prod-shade">{prop.data?.post?.shade}</p>
					<div className="rating-section">
						<img className="icon" src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-star.svg`} alt="ic-star" width="11" />
						<p className="rating-average">
							{prop.data?.post?.rating}
						</p>
						<p className="review-total">
							(
							{prop.data?.post?.total_review}
							&nbsp;
							Review)
						</p>
					</div>
				</div>
			</div>

			<div className="review-section">
				<div className="section-user">
					<a className="image-user" href={prop.linkUser}>
						<img src="https://reviews.mommiesdaily.com/_nuxt/img/user.75f3b26.jpg" alt="user-review" />
					</a>
					<div className="user-info">
						<a href={prop.linkUser} className="txt-username">{prop.data.user.username}</a>
						<div className="rating-star">
							<RatingStar
								is_mobile
								rating={prop.data?.post?.rating}
								type="3"
							/>
						</div>
					</div>
				</div>
				<p className="txt-recomend">
					<img className="icon" src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-recomend.svg`} alt="ic-recomend" width="13" />
					{prop.data?.user?.username}
					&nbsp;
					recommends this product!
				</p>
				<p className="txt-review">
					{prop.data?.review}
				</p>
				{
					prop.data?.review?.length > 170 && <a href={process.env.NEXT_PUBLIC_MD_REVIEW} className="txt-more">Read more</a>
				}
			</div>
			<style jsx>
				{`
				.txt-more {
					color: #db284e;
					font-family: Roboto;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;

				}
				.rating-star {
					// display: flex;
					// gap: 4px;
					// margin: 5px 0 0 2px
				}
				.section-user {
					display: flex;
					gap: 20px;
				}
				.txt-username {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 600;
					line-height: 17px;
					letter-spacing: 0em;
					text-align: left;
					text-transform: uppercase;
					margin: 0;
					color: #000;
				}
				.image-user {

					width: 40px;
					height: 40px;
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.image-user img {
					max-height: 100%;
					max-width: 100%;
				}
				.txt-recomend {
					font-family: Roboto;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
					background: #EEF8EC;
					color: #38812A;
					padding: 5px;
					border-radius: 4px;

					display: flex;
					gap: 5px;
				}
				.review-section {
					padding: 13px;
					height: 172px;
				}
				.txt-review {
					font-family: Roboto;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					margin: 0;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;

				}
				.product-section {
					display: flex;
					gap: 16px;
					align-items: center;
					padding: 8px;
					border-bottom: 3px solid #F0F3F7;
				}
				.icon {
					margin-top: -1px;
				}
				.rating-section {
					display: flex;
					align-items: center;
					gap: 5px;
				}
				.rating-average {
					font-family: Roboto;
					font-size: 12px;
					font-weight: 400;
					line-height: 11px;
					letter-spacing: 0em;
					text-align: left;
					margin: 5px 0;
				}
				.review-total {
					font-family: Roboto;
					font-size: 12px;
					font-weight: 400;
					line-height: 11px;
					letter-spacing: 0em;
					text-align: left;
					margin: 5px 0;
					color: #637381;

				}
					.prod-brand {
						font-family: Poppins;
						font-size: 14px;
						font-weight: 600;
						line-height: 17px;
						letter-spacing: 0em;
						text-align: left;
						margin: 5px 0;
					}
					.prod-brand a{ 
						color: #000;
					}
					.prod-name {
						font-family: Roboto;
						font-size: 12px;
						font-weight: 400;
						line-height: 14px;
						letter-spacing: 0em;
						text-align: left;
						margin: 5px 0;
						
					}
					.prod-name a{
						color: #000;
					}
					.prod-shade {
						font-family: Roboto;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0em;
						text-align: left;
						color: #637381;
						margin: 5px 0;

					}
          .review {
            background: #fff;
            border-radius: 4px;
          }
					.image-container {
						width: 100px;
						height: 100px;
						flex-shrink: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.image-container img {
						max-height: 100%;
						max-width: 100%;
					}
        `}
			</style>
		</div>
	);

};

export default ReviewCard;
