import React from "react";
import dynamic from "next/dynamic";

const MobileLarge1 = dynamic(
	() => import("./mobile_large1"),
);
const MobileLarge2 = dynamic(
	() => import("./mobile_large2"),
);
const MobileLarge3 = dynamic(
	() => import("./mobile_large3"),
);
const MobileDefault = dynamic(
	() => import("./mobile_default"),
);
const MobileSmall = dynamic(
	() => import("./mobile_small"),
);
const MobileSmall2 = dynamic(
	() => import("./mobile_small2"),
);
const MobileDetik = dynamic(
	() => import("./mobile_detik"),
);
const MobileQuiz = dynamic(
	() => import("./mobile_quiz"),
);
const MobileVideo = dynamic(
	() => import("./mobile_video"),
);
const MobileMedium1 = dynamic(
	() => import("./mobile_medium1"),
);
const MobileMedium2 = dynamic(
	() => import("./mobile_medium2"),
);
const MobileMedium3 = dynamic(
	() => import("./mobile_medium3"),
);
const Mobile = ({ ...props }) => {

	const prop = props;

	const Template = [
		{
			type: "LARGE-1",
			component: MobileLarge1,
		},
		{
			type: "LARGE-2",
			component: MobileLarge2,
		},
		{
			type: "LARGE-3",
			component: MobileLarge3,
		},
		{
			type: "MEDIUM-1",
			component: MobileMedium1,
		},
		{
			type: "MEDIUM-2",
			component: MobileMedium2,
		},
		{
			type: "MEDIUM-3",
			component: MobileMedium3,
		},
		{
			type: "SMALL",
			component: MobileSmall,
		},
		{
			type: "SMALL-2",
			component: MobileSmall2,
		},
		{
			type: "DETIK",
			component: MobileDetik,
		},
		{
			type: "VIDEO",
			component: MobileVideo,
		},
		{
			type: "QUIZ",
			component: MobileQuiz,
		},
		{
			type: "DEFAULT",
			component: MobileDefault,
		},
	];

	const data = {
		...prop,
		type: prop.type || "DEFAULT",
	};

	return (
		<>
			{Template.filter((item) => item.type === data.type).map(
				(article) => (
					<article.component {...data} key={data.type} />
				),
			)}
		</>
	);

};

export default Mobile;
