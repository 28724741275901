import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";
import ShimmerEducation from "Components/shimmer/shimmer_education";

const mobile = ({ ...props }) => {

	const prop = props;
	return (
		<div className="container">
			{
				!prop.ArticleEducation?.data?.data ? (
					<ShimmerEducation isMobile={prop.isMobile} />
				) : (
					<>
						<div className="title">
							<TitleSection
								title="Education"
								color="#84BCBA"
								isLine
								isBackground
								isMobile={prop.isMobile}
							/>
						</div>
						<section className="article-update">
							<div className="scrolling-list">
								{prop.ArticleEducation?.data?.data?.map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											type="MEDIUM-2"
											title={item?.title}
											category={
												item?.category?.[0]?.name || null
											}
											catSlug={
												item?.category?.[0]?.slug || null
											}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
										/>
									</div>
								))}
							</div>
						</section>
						<a href={prop.linkMore} className="link">
							<p className="link-more">
								READ MORE
								<img
									src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`}
									alt="ic-arrow-right"
									width="17"
								/>
							</p>
						</a>
					</>
				)
			}

			<style jsx>
				{`
					.title {
						margin: 30px 0;
					}
					.link {
						width: inherit;
						color: inherit;
						text-decoration: none;
						display: flex;
						justify-content: center;
					}
					.link-more {
						font-family: Poppins;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: 20px 16px 0 16px;
						border-bottom: 1px solid #000;
						padding: 8px;
						text-align: center;

						width: 130px;
						display: flex;
    					justify-content: space-around;
					}
					.scrolling-list {
						display: flex;
						justify-content: space-between;
						width: 100%;
						flex-flow: row;
						overflow-x: scroll;
						gap: 16px;
					}
					.small {
						padding: 16px 0;
						width: 239px;
						flex-shrink: 0;
					}
					.container {
						margin: 52px 0 30px 0;
					}

					.article-update {
						padding: 0px 16px 20px 16px;
					}
				`}
			</style>
		</div>
	);

};

export default mobile;
