import React from "react";
import ArticleCard from "Components/article_card";
import ShimmerQuiz from "Components/shimmer/shimmer_quiz";

const desktop = ({ ...props }) => {

	const prop = props;

	return (
		<>
			{
				!prop.Quiz?.data?.data ? (
					<ShimmerQuiz isMobile={prop.isMobile} />
				) : (
					<div className="container" data-title="Quiz">
						<>
							<section className="article-update">
								<div className="scrolling-list">
									{

										prop.Quiz?.data?.data?.map((item) => (
											<div className="small" key={item?.id}>
												<ArticleCard
													isMobile={prop.isMobile}
													type="DEFAULT"
													title={item?.title}
													category={
														item?.category?.[0]?.name || null
													}
													catSlug={
														item?.category?.[0]?.slug || null
													}
													image={item?.image}
													author={item?.author}
													date={item?.created_at}
													slug={item?.slug}
													width={274}
												/>
											</div>
										))
									}
								</div>

							</section>
							<a href={prop.linkMore} className="link">
								<p className="link-more">
									PLAY MORE QUIZ
									<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`} alt="ic-arrow-right" width="17" />
								</p>
							</a>
						</>

						<style jsx>
							{`
						.link {
							color: inherit;
							text-decoration: none;
							display: flex;
							justify-content: center;
							width: inherit;
						}
						.link-more {
							font-family: Poppins;
							font-size: 16px;
							font-weight: 400;
							line-height: 22px;
							letter-spacing: 0px;
							margin: 0 16px 0 16px;
							border-bottom: 1px solid #000;
							padding: 8px;
							text-align: center;
							justify-content: space-between;
							display: flex;
							width: 162px;
							font-style: normal;
							color: #000000;
						}
						.scrolling-list {
							display: flex;
							justify-content: space-between;
							width: 100%;
							flex-flow: row;
							overflow-x: scroll;
							gap: 36px;
						}
						.small {
							flex-shrink: 0;
						}
						.container {
							margin: 52px auto 36px auto;
							max-width: 1280px;
							border: 0.5px solid#d42428;
							border-bottom: none;
							padding: 62px 0 73px 0;
							box-shadow: 0 3px 1px -1px #f7d3dc, 
										0 4px 1px -1.5px #f7d3dc, 
										0 5px 1px -2px #f7d3dc, 
										0 6px 1px -2.5px #f7d3dc, 
										0 7px 1px -3px #f7d3dc, 
										0 8px 1px -3.5px #f7d3dc, 
										0 9px 1px -4px #f7d3dc, 
										0 10px 1px -4.5px #f7d3dc, 
										0 11px 1px -5px #f7d3dc, 
										0 12px 1px -5.5px #f7d3dc, 
										0 13px 1px -6px #f7d3dc, 
										0 14px 1px -6.5px #f7d3dc, 
										0 15px 1px -7px #f7d3dc, 
										0 16px 1px -7.5px #f7d3dc;
							position: relative;
						}

						.container::before {
							position: absolute;
							content: attr(data-title);
							color: #000;
							font-family: 'Brygada 1918';
							font-style: italic;
							font-weight: 700;
							font-size: 40px;
							line-height: 12px;
							letter-spacing: 0em;
							// z-index: 1;
							// top: 4px;
							left: 40px;
							top: -6px;
							background: #fff;
							// width: auto;
							padding: 0 10px;
						} 

						.article-update {
							padding: 0px 56px 20px 56px;
						}
				`}

						</style>

					</div>
				)
			}

		</>

	);

};

export default desktop;
