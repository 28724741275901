import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const MostUpdateSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		SingleArticle: prop.ArticleMost?.data?.data?.[0] ? prop.ArticleMost.data.data[0] : {},
		linkMore: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/recent/`,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default MostUpdateSection;
