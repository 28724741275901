import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import ShimmerMidlife from "Components/shimmer/shimmer_midlife";
import isMobilePlugin from "is-mobile";
import ImageFallback from "Components/image_fallback";

const categories = [
	{ title: "Working Issue", slug: "mommies_working-it" },
	{ title: "Self", slug: "self" },
	{ title: "Lifestyle", slug: "lifestyle" },
	{ title: "Sex & Relationship", slug: "relationship" },
];

const MidlifeContent = dynamic(() => import("./midlife_content"), {
	ssr: false,
	loading: () => <ShimmerMidlife isMobile={isMobilePlugin} />
	,
});

const SectionMidlife = ({ isMobile, articles }) => {

	const device = isMobile ? "mobile" : "desktop";
	return (
		<>
			<div className="midlife">
				<div className={`midlife__wrapper midlife__wrapper--${device}`}>
					<div className={`midlife__title-category midlife__title-category--${device}`}>
						<div className={`midlife__title-section midlife__title-section--${device}`}>
							<div className={`midlife__logo midlife__logo--${device}`}>
								<ImageFallback src={process.env.NEXT_PUBLIC_MIDLIFE_LOGO} alt="midlife" width={794} height={326} />
							</div>
							<div className={`midlife__title midlife__title--${device}`}>
								<p className={`midlife__title-forty midlife__title-forty--${device}`}>Forty & Fearless:</p>
								<p className={`midlife__subtitle midlife__subtitle--${device}`}>Embrace Your Boldest Chapter Yet </p>
							</div>
						</div>
						<div className={`midlife__categories midlife__categories--${device}`}>
							{categories?.map((category) => (

								<a
									key={category.slug}
									href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/${category.slug}`}
									className={`midlife__category midlife__category--${device}`}
								>
									{category.title}
								</a>
							))}
						</div>
					</div>
					<div className={`midlife__articles midlife__articles--${device}`}>
						<MidlifeContent isMobile={isMobile} articles={articles} />
					</div>
				</div>
			</div>

			<style jsx>
				{`
					.midlife {
						width: 100%;
						background: #FEF7F8;
					}
					.midlife__wrapper {
						max-width: 1440px;
						padding: var(--space-0) var(--space-80);
						display: grid;
						grid-template-columns: 397px 1fr;
						gap: var(--space-48);
						margin: auto;
					}
					.midlife__wrapper--mobile {
						display: flex;
						flex-direction: column;
						max-width: 480px;
						gap: var(--space-36);
						padding: var(--space-0);
					}

					.midlife__title-section--mobile {
						text-align: center;
					}

					.midlife__logo {
						width: 391px;
						margin: auto;
					}

					.midlife__logo--mobile {
						width: 280px;
					}

					.midlife__title-category {
						padding: var(--space-80) var(--space-0);
					}

					.midlife__title-category--mobile {
						padding: var(--space-32) var(--space-16) var(--space-0) var(--space-16);
					}

					.midlife__title {
						display: flex;
						flex-direction: column;
						gap: 10px;
						margin-top: -16px;
					}

					.midlife__title--mobile {
						margin-top: var(--space-4);
					}

					.midlife__title-forty {
						width: fit-content;
						font-family: "Brygada 1918", sans-serif;
						font-size: 24px;
						font-style: normal;
						font-weight: 500;
						line-height: 26.5px;
						text-transform: capitalize;
						padding: var(--space-4) var(--space-12);
						background-color: #13161A;
						color: var(--text-default-inverse);
						margin: var(--space-0) auto;
					}

					.midlife__title-forty--mobile {
						font-size: 16px;
					}

					.midlife__subtitle {
						color: var(--Color-Text-text-default, #13161A);
						text-align: center;
						font-family: "Brygada 1918", sans-serif;
						font-size: 32px;
						font-style: normal;
						font-weight: 500;
						line-height: 38.5px;
						text-transform: capitalize;
						margin: 0px;
					}

					.midlife__subtitle--mobile {
						font-size: 20px;
					}

					.midlife__categories {
						display: flex;
						justify-content: center;
						gap: 12px 20px;
						flex-wrap: wrap;
						margin-top: var(--space-64);
					}

					.midlife__categories--mobile {
						margin-top: var(--space-20);
					}

					.midlife__category {
						color: var(--text-default);
						text-align: center;
						font-family: Poppins;
						font-size: 24px;
						font-style: normal;
						font-weight: 600;
						line-height: 28px;
						text-transform: capitalize;
						padding: var(--space-12);
						border: 1px solid var(--border-strong);
						border-bottom: 3px solid var(--border-strong);
					}

					.midlife__category:hover {
						background-color: #DF3D5F;
						color: var(--text-default-inverse);
					}

					.midlife__category--mobile:hover {
						background-color: transparent;
						color: var(--text-default);
					}

					.midlife__category:active {
						background-color: #AC1D3E;
						color: var(--text-default-inverse);
					}

					.midlife__category--mobile {
						font-size: 16px;
					}

					.midlife__articles {
						display: flex;
						align-items: flex-start;
						gap: 48px;
						padding: var(--space-40) var(--space-0);
					}

					.midlife__articles--mobile {
						flex-direction: column;
						padding-top: var(--space-0);
						padding-bottom: var(--space-32);
						gap: 24px;
					}
				`}
			</style>
		</>
	);

};

SectionMidlife.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	articles: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		url: PropTypes.string,
	})).isRequired,
};

export default SectionMidlife;
