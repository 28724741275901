import React, { useState } from "react";
import ArticleCard from "Components/article_card";
import TitleSection from "Components/title_section";
import Vars from "Consts/vars";

const mobile = ({ ...props }) => {

	const prop = props;

	const [ActiveTab, setActiveTab] = useState(0);

	const handleTab = (params) => {

		setActiveTab(params);

	};

	return (
		<div className="tab">

			<div className="list-top-tab">

				{Vars.COLOR_TAB.map((item) => (

					<button type="button" key={item.id} onClick={() => handleTab(item.id)} className="top-tab" style={{ background: item.color }}>
						<p className="number-tab">
							{item.id + 1}
						</p>

					</button>
				))}
			</div>

			<section className="article-hottest" style={{ background: `${Vars.COLOR_TAB.filter((item) => item.id === ActiveTab)[0].color}` }}>
				<div className="title-container">
					<TitleSection
						isMobile={prop.isMobile}
						title="Most Read This Week"
						color="#D42428"
						colorFont="#fff"
						isLine
					/>
				</div>

				{

					prop.ArticlePopular.filter((items) => items.id === ActiveTab).map((item) => (

						<ArticleCard
							key={item.id}
							type="LARGE-2"
							date={item.date}
							image={item.image}
							title={item.title}
							excerpt={item.excerpt}
							author={item.author}
							slug={item.slug}
							isMobile={prop.isMobile}
						/>

					))
				}

			</section>
			<style jsx>
				{`
        .title-container {
          margin: 0 0 45px 0;
        }
        .number-tab {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
          color: #FFFFFF;
        }
        .list-top-tab {
          display: flex;
        }
        .top-tab {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          height: 62px;

          border-radius: 8px 8px 0px 0px;


          flex: none;
          order: 0;
          flex-grow: 0;
          border: 0px;
        }
        .article-hottest {
          padding: 35px 16px 40px 16px;
          margin-top: -1px;
        }
      `}
			</style>
		</div>
	);

};

export default mobile;
