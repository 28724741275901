import React from "react";
import ArticleCard from "Components/article_card";

const mobile = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			<section className="article-section">
				<div className="scrolling-list">
					{prop.ArticleZone?.data?.data?.map((item) => (
						<div className="item-article" key={item.id}>
							<ArticleCard
								title={item?.title}
								category={
									item?.category?.[0]?.name || null
								}
								catSlug={
									item?.category?.[0]?.slug || null
								}
								image={item?.image}
								excerpt={item?.excerpt}
								author={item?.author}
								date={item?.created_at}
								slug={item?.slug}
								type="LARGE-1"
								isMobile={prop.isMobile}
							/>
						</div>
					))}
				</div>
			</section>
			<style jsx>
				{`
					.item-article {
						width: 330px;
						margin-bottom: 20px;
						flex-shrink: 0;
					}
					.article-section {
						background: #f0f3f7;
						padding: 50px 16px;
						margin-bottom: -5px;
					}
					.scrolling-list {
						display: flex;
						justify-content: space-between;
						width: 100%;
						flex-flow: row;
						overflow-x: scroll;
						gap: 16px;
					}
				`}
			</style>
		</div>
	);

};

export default mobile;
