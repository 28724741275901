import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const ReviewSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		linkMore: process.env.NEXT_PUBLIC_MD_REVIEW,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default ReviewSection;
