import React from "react";
import dynamic from "next/dynamic";

const DesktopLarge = dynamic(
	() => import("./desktop_large"),
);
const DesktopSquare = dynamic(
	() => import("./desktop_square"),
);
const DesktopSquare2 = dynamic(
	() => import("./desktop_square2"),
);
const DesktopSquare3 = dynamic(
	() => import("./desktop_square3"),
);
const DesktopDefault = dynamic(
	() => import("./desktop_default"),
);
const DesktopSmall = dynamic(
	() => import("./desktop_small"),
);
const DesktopSmall2 = dynamic(
	() => import("./desktop_small2"),
);
const DesktopVideo = dynamic(
	() => import("./desktop_video"),
);
const DesktopDetik = dynamic(
	() => import("./desktop_detik"),
);

const Desktop = ({ ...props }) => {

	const prop = props;

	const Template = [
		{
			type: "LARGE",
			component: DesktopLarge,
		},
		{
			type: "SQUARE",
			component: DesktopSquare,
		},
		{
			type: "SQUARE-2",
			component: DesktopSquare2,
		},
		{
			type: "SQUARE-3",
			component: DesktopSquare3,
		},
		{
			type: "SMALL",
			component: DesktopSmall,
		},
		{
			type: "SMALL-2",
			component: DesktopSmall2,
		},
		{
			type: "VIDEO",
			component: DesktopVideo,
		},
		{
			type: "DETIK",
			component: DesktopDetik,
		},
		{
			type: "DEFAULT",
			component: DesktopDefault,
		},
	];

	const data = {
		...prop,
		type: prop.type || "DEFAULT",
	};

	return (
		<>
			{Template.filter((item) => item.type === data.type).map(
				(article) => (
					<article.component {...data} key={data.type} />
				),
			)}
		</>
	);

};

export default Desktop;
