import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";
import ShimmerParenting from "Components/shimmer/shimmer_parenting";

const mobile = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			{
				!prop.ArticleParenting?.data?.data ? (
					<ShimmerParenting isMobile={prop.isMobile} />
				) : (
					<>
						<div className="title">
							<TitleSection
								title="Parenting & Kids"
								color="#ECB966"
								isLine
								isBackground
								isMobile={prop.isMobile}
							/>
						</div>
						<section className="article-update">
							<ArticleCard
								isMobile={prop.isMobile}
								type="DEFAULT"
								title={prop.SingleArticle?.title}
								category={prop.SingleArticle?.category?.[0]?.name || null}
								catSlug={prop.SingleArticle?.category?.[0]?.slug || null}
								image={prop.SingleArticle?.image}
								author={prop.SingleArticle?.author}
								slug={prop.SingleArticle?.slug}
								date={prop.SingleArticle?.created_at}
							/>
							<div className="list-article">
								{prop.ArticleParenting?.data?.data?.slice(1, 5).map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											type="MEDIUM-1"
											title={item?.title}
											category={item?.category?.[0]?.name || null}
											catSlug={item?.category?.[0]?.slug || null}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
										/>
									</div>
								))}
							</div>

						</section>
						<a href={prop.linkMore} className="link">
							<p className="link-more">

								READ MORE
							</p>
						</a>

					</>
				)
			}

			<style jsx>
				{`
					.title {
						margin: 30px 0;
					}
					a, a:hover, a:visited, a:active {
						color: inherit;
						text-decoration: none;
					}
					.link-more {
						font-family: Poppins;
						font-size: 18px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: 0 16px;
						border: 1px solid #000;
						padding: 16px;
						text-align: center;
					}
					.list-article {
						margin: 16px 0 14px 0;
						display: grid;
						grid-column-gap: 20px;
						grid-row-gap: 20px;
						grid-template-columns: repeat(2, 1fr);
					}
					.small {
						padding: 16px 0;
					}
					.container {
						margin: 24px 0 30px 0;
					}

					.article-update {
						padding: 0 16px;
					}
				`}

			</style>

		</div>
	);

};

export default mobile;
