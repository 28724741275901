import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const EducationSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		linkMore: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/education/`,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default EducationSection;
