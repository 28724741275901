import React from "react";
import ArticleCard from "Components/article_card";
import ShimmerQuiz from "Components/shimmer/shimmer_quiz";

const mobile = ({ ...props }) => {

	const prop = props;
	return (
		<div className="container" data-title="Quiz">
			{
				!prop.Quiz?.data?.data ? (
					<ShimmerQuiz isMobile={prop.isMobile} />
				) : (
					<>
						<section className="article-update">
							<div className="scrolling-list">
								{prop.Quiz?.data?.data?.map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											type="QUIZ"
											title={item?.title}
											category={item?.category?.[0]?.name || null}
											catSlug={item?.category?.[0]?.slug || null}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
										/>
									</div>
								))}
							</div>

						</section>
						<a href={prop.linkMore} className="link">
							<p className="link-more">
								PLAY MORE QUIZ
								<img src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/ic-arrow-right.svg`} alt="ic-arrow-right" width="17" />
							</p>
						</a>

					</>
				)
			}

			<style jsx>
				{`
          .link {
						color: inherit;
						text-decoration: none;
						display: flex;
						justify-content: center;
						width: inherit;
					}
					.link-more {
						font-family: Poppins;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						margin: 0 16px 0 16px;
						border-bottom: 1px solid #000;
						padding: 8px;
						text-align: center;
						justify-content: space-between;
						display: flex;
						width: 162px;
					}
					.scrolling-list {
						display: flex;
						justify-content: space-between;
						width: 100%;
						flex-flow: row;
						overflow-x: scroll;
						gap: 16px;
					}
          .small {
            padding: 16px 0;
						width: 239px;
						flex-shrink: 0;
          }
          .container {
						margin: 52px 0 36px 16px;
						border: 0.5px solid#d42428;
						border-right: none;
						padding: 30px 0;
						box-shadow: 
								1px 1px 0px #F7D3DC,
                2px 2px 0px #F7D3DC,
                3px 3px 0px #F7D3DC,
                4px 4px 0px #F7D3DC,
                5px 5px 0px #F7D3DC,
                6px 6px 0px #F7D3DC,
                7px 7px 0px #F7D3DC,
                8px 8px 0px #F7D3DC,
                9px 9px 0px #F7D3DC,
                10px 10px 0px #F7D3DC;
						position: relative;

          }

					.container::before {
						position: absolute;
						content: attr(data-title);
						color: #000;
						font-family: 'Brygada 1918';
						font-style: italic;
						font-weight: 700;
						font-size: 24px;
						line-height: 12px;
						letter-spacing: 0em;
						// z-index: 1;
						// top: 4px;
						left: 40px;
						top: -6px;
						background: #fff;
						// width: auto;
						padding: 0 10px;
					} 

          .article-update {
            padding: 0px 16px 20px 16px;
          }
        `}

			</style>

		</div>
	);

};

export default mobile;
