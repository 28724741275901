import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const QuizSection = (props) => {

	const prop = props;

	const data = {
		...prop,
		linkMore: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/quiz/`,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default QuizSection;
