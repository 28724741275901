import React from "react";
import Shimmer from "Components/shimmer";

const Card = () => (
	<div className="card">
		<div className="left">
			<Shimmer width="139px" height="139px" />
		</div>
		<div className="right">
			<Shimmer width="30%" height="20px" />
			<Shimmer width="100%" height="56px" />
			<Shimmer width="40%" height="17px" />
		</div>
		<style jsx>
			{`
        .left {
          flex-shrink: 0;
        }
		.right{
			width: 70%;
		}
        .card {
          display: flex;
          gap: 20px;
        }
		.author{
			margin-top:  27px;
		}
      `}
		</style>

	</div>
);

const Desktop = () => (
	<div className="article-shimmer">
		<div className="title">
			<Shimmer width="332px" height="47px" />
		</div>
		<div className="article-item">
			{
				[0, 1, 2, 3, 4, 5].map((item) => (
					<Card key={item} />
				))
			}
		</div>

		<style jsx>
			{`
				.article-shimmer {
					margin: 40px auto 30px auto;
					max-width: 1280px;
				}
				.title{
					display: grid;
					margin-bottom: 60px;
					align-items: center;
					justify-items: center;
				}
				.article-item{
					display: grid;
					grid-template-columns: auto auto;
				}
			`}

		</style>
	</div>
);

export default Desktop;
