import React from "react";
import Banner from "Components/banner/banner";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";
import ShimmerEducation from "Components/shimmer/shimmer_education";

const Desktop = ({ ...props }) => {

	const prop = props;
	return (
		<div className="container">
			{
				!prop.ArticleEducation?.data?.data ? (
					<ShimmerEducation isMobile={prop.isMobile} />
				) : (
					<>
						<div className="title">
							<TitleSection
								title="Education"
								color="#84BCBA"
								isLine
								isBackground
								isMobile={prop.isMobile}
							/>
						</div>
						<section className="article-update">
							<div className="list-data">
								<Banner
									name="mediumrectangle2"
									placeholder="mediumrectangle2"
									class_name="ads-mediumrectangle2"
									isMobile={prop.isMobile}
								/>
								<div className="scrolling-list">
									{prop.ArticleEducation?.data?.data?.map((item) => (
										<div className="small" key={item?.id}>
											<ArticleCard
												isMobile={prop.isMobile}
												isDate
												type="DEFAULT"
												width={274}
												title={item?.title}
												category={
													item?.category?.[0]?.name || null
												}
												catSlug={
													item?.category?.[0]?.slug || null
												}
												image={item?.image}
												author={item?.author}
												date={item?.created_at}
												slug={item?.slug}
											/>
										</div>
									))}
								</div>
							</div>
						</section>
						<div className="container-button">
							<a href={prop.linkMore} className="link">
								<p className="link-more">
									READ MORE
								</p>
							</a>
						</div>
					</>
				)
			}

			<style jsx>
				{`
					.list-data {
						display: flex;
						gap: 28px;
					}
					.title {
						margin: 30px 0;
					}
					a,
					a:hover,
					a:visited,
					a:active {
						color: inherit;
						text-decoration: none;
					}

					.container-button {
						display: flex;
						justify-content: center;
						margin: 48px 0;
					}

					.link-more {
						font-family: Poppins;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						text-align: center;

						width: 130px;
						border: 1px solid #000;
						padding: 14px 83px;
						margin: 0;
					}

					.scrolling-list {
						display: flex;
						width: 100%;
						flex-flow: row;
						overflow-x: scroll;
						gap: 28px;
					}

					.small {
						flex-shrink: 0;
						margin: 0 0 10px 0;
					}

					.container {
						margin: 52px 0 30px 0;
					}

					.article-update {
						padding: 0px 16px 0px 80px;
						margin-top: 62px;

						max-width: 1280px;
						margin: 62px auto 0 auto;
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
