import React from "react";
import Mobile from "./mobile";
import Desktop from "./desktop";

const DetikSection = (props) => {

	const prop = props;

	if (prop.isMobile) {

		return (
			<Mobile
				{...prop}
			/>
		);

	}
	return (
		<Desktop {...prop} />
	);

};

export default DetikSection;
