import React from "react";
import { momentDate, momentDateUTC } from "Helpers/utils";
import Mobile from "./mobile";
import Desktop from "./desktop";

const ArticleCard = (props) => {

	const prop = props;

	const data = {
		...prop,
		image: prop.image || process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_RECTANGLE || "",
		category: prop.category ? prop.category.replace(/&amp;/g, "&") : "?category?",
		catSlug: prop.catSlug ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/${prop.catSlug.replace(/_/g, "-")}` : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/category/sponsored-post`,
		title: prop.title || "?Title?",
		link: prop.extraLink ? prop.extraLink : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${momentDateUTC(prop.date)}/${prop.slug}/` || "https://mommiesdaily.com/",
		date: prop.date ? momentDate({ date: prop.date }) : "?date?",
		authorName: prop.author?.name ? prop.author.name : "?author?",
		authorSlug: prop.author?.username ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/${prop.author.username}` : `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/mommiesdaily`,
		authorImage: prop.author?.image?.xtra_small || "https://image.femaledaily.com/dyn/100/images/user-pics/placeholder_user.png",
		isDate: prop.isDate || false,
		isImage: prop.isImage || false,
		isSponsore: prop.isSponsore || false,
	};

	if (prop.isMobile) {

		return (
			<Mobile
				{...data}
			/>
		);

	}
	return (
		<Desktop {...data} />
	);

};

export default ArticleCard;
