import React from "react";
import TitleSection from "Components/title_section";
import ArticleCard from "Components/article_card";
import ShimmerParenting from "Components/shimmer/shimmer_parenting";

const Desktop = ({ ...props }) => {

	const prop = props;

	return (
		<div className="container">
			{
				!prop.ArticleParenting?.data?.data ? (
					<ShimmerParenting isMobile={prop.isMobile} />
				) : (
					<>
						<div className="title">
							<TitleSection
								title="Parenting & Kids"
								color="#ECB966"
								isLine
								isBackground
								isMobile={prop.isMobile}
							/>
						</div>
						<section className="article-update">
							<ArticleCard
								isMobile={prop.isMobile}
								type="LARGE"
								title={prop.SingleArticle?.title}
								excerpt={prop.SingleArticle?.excerpt}
								category={prop.SingleArticle?.category?.[0]?.name || null}
								catSlug={prop.SingleArticle?.category?.[0]?.slug || null}
								image={prop.SingleArticle?.image}
								author={prop.SingleArticle?.author}
								slug={prop.SingleArticle?.slug}
								date={prop.SingleArticle?.created_at}
							/>
							<div className="list-article">
								{prop.ArticleParenting?.data?.data?.slice(1, 5).map((item) => (
									<div className="small" key={item?.id}>
										<ArticleCard
											isMobile={prop.isMobile}
											type="DEFAULT"
											fontSize="20px"
											width={274}
											title={item?.title}
											category={item?.category?.[0]?.name || null}
											catSlug={item?.category?.[0]?.slug || null}
											image={item?.image}
											author={item?.author}
											date={item?.created_at}
											slug={item?.slug}
										/>
									</div>
								))}
							</div>

						</section>
						<div className="container-button">
							<a href={prop.linkMore} className="link">
								<p className="link-more">READ MORE</p>
							</a>
						</div>

					</>
				)
			}
			<style jsx>
				{`
					.title {
						margin: 30px 0;
					}
					a, a:hover, a:visited, a:active {
						color: inherit;
						text-decoration: none;
					}

					.container-button {
						display: flex;
						justify-content: center;
						margin: 48px 0;
					}

					.link-more {
						font-family: Poppins;
						font-size: 18px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;

						width: 130px;
						border: 1px solid #000;
						padding: 14px 83px;
						text-align: center;
						margin: 0;
					}

					.list-article {
						display: grid;
						grid-column-gap: 32px;
						grid-row-gap: 19px;
						grid-template-columns: repeat(2, 274px);
					}

					.small {
						// padding: 16px 0;
					}

					.container {
						margin: 24px 0 30px 0;
					}

					.article-update {
						padding: 0 80px;
						display: grid;
						max-width: 100%;
						gap: 36px;
						grid-template-columns: 666px auto;
						// margin-top: 62px;

						max-width: 1280px;
						margin: 62px auto 0 auto;
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
