import Shimmer from "Components/shimmer";
import PropTypes from "prop-types";

const ShimmerMidlife = ({ isMobile }) => {

	const device = isMobile ? "mobile" : "desktop";

	return (
		<div className={`midlife-shimmer midlife-shimmer--${device}`}>
			<div className={`midlife-shimmer__articles-big midlife-shimmer__articles-big--${device}`}>
				{[...Array(2).keys()].map((key) => (
					<div key={key} className={`midlife-shimmer__article-big midlife-shimmer__article-big--${device}`}>
						<div className={`midlife-shimmer__article-big-image midlife-shimmer__article-big-image--${device}`}>
							<Shimmer width="100%" height="100%" />
						</div>
						<Shimmer width="61px" height="12px" />
						<Shimmer width="100%" height="12px" />
						<Shimmer width="100%" height="12px" />
					</div>
				))}
			</div>
			<div className="midlife-shimmer__articles-small">
				{[...Array(4).keys()].map((key) => (
					<div key={key} className={`midlife-shimmer__article-small midlife-shimmer__article-small--${device}`}>
						<div className={`midlife-shimmer__article-small-image midlife-shimmer__article-small-image--${device}`}>
							<Shimmer width="100%" height="100%" />
						</div>

						<div className="midlife-shimmer__article-small-content">
							<Shimmer width="61px" height="12px" />
							<Shimmer width="100%" height="12px" />
							<Shimmer width="100%" height="12px" />
						</div>
					</div>
				))}
			</div>

			<style jsx>
				{`     
                    .midlife-shimmer {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 397px 1fr;
                    }

                    .midlife-shimmer--mobile {
                        display: flex;
						flex-direction: column;
						max-width: 480px;
                        gap: 32px;
                    }
    
                    .midlife-shimmer__articles-big {
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
                        width: 274px;
                        margin-left: var(--space-64);
                    }

                    .midlife-shimmer__articles-big--mobile {
                        width: 100%;
                        max-width: 480px;
						height: auto;
						margin-left: var(--space-0);
						display: flex;
						justify-content: space-between;
						flex-flow: row;
						overflow-y: hidden;
						overflow-x: scroll;
						gap: 16px;
						padding: var(--space-0) var(--space-16);
						box-sizing: border-box;
                    }
    
                    .midlife-shimmer__article-big {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                    }

                    .midlife-shimmer__article-big--mobile {
                        min-width: 240px;
                    }
    
                    .midlife-shimmer__article-big-image {
                        height: 164px;
                    }

                    .midlife-shimmer__article-big-image--mobile {
                        height: 120px;
                    }
    
                    .midlife-shimmer__articles-big :global(.fdn-shimmer),
                    .midlife-shimmer__articles-small :global(.fdn-shimmer) {
                        margin-bottom: var(--space-0);
                    }
    
                    .midlife-shimmer__articles-small {
                        display: flex;
                        flex-direction: column;
                        gap: 32px;
                        width: 100%;
                    }
    
                    .midlife-shimmer__article-small {
                        display: flex;
                        width: 100%;
                        gap: 32px;
                    }

                    .midlife-shimmer__article-small--mobile {
                        gap: 12px;
                        width: auto;
                        padding: var(--space-0) var(--space-16);
                    }
    
                    .midlife-shimmer__article-small-image {
                        height: 139px;
                        min-width: 139px;
                    }

                    .midlife-shimmer__article-small-image--mobile {
                        height: 94px;
                        min-width: 94px;
                    }
    
                    .midlife-shimmer__article-small-content {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    }
                `}
			</style>
		</div>
	);

};

ShimmerMidlife.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default ShimmerMidlife;
