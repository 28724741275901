/* eslint-disable react/jsx-indent */
import React from "react";
import Banner from "Components/banner/banner";
import env from "Consts/env";
import IconZigzag from "Components/icons/zigzag";
import { arrayCheck } from "Helpers/utils";
import { useSelector } from "react-redux";
import SectionHottest from "./section/section_hottest";
import SectionMostUpdate from "./section/section_most_update";
import SectionDetik from "./section/section_detik";
import SectionPowerHour from "./section/section_power_hour";
import SectionParenting from "./section/section_parenting";
import SectionEducation from "./section/section_education";
import SectionRelation from "./section/section_relation";
import SectionQuiz from "./section/section_quiz";
import SectionZone from "./section/section_zone";
import SectionReview from "./section/section_review";
import SectionWhatsOnFd from "./section/section_whats_on_fd";
import SectionMidlife from "./section/section_midlife";
import SectionYoutubeVideo from "./section/section_youtube_video";

const Desktop = ({ ...props }) => {

	const prop = props;
	const discoverVideos = useSelector((state) => state.storeDiscoverVideo);
	const youtubeVideos = useSelector((state) => state.storeYoutubeVideo);
	const articleMidlife = useSelector((state) => state.storeArticleMidlife);

	return (
		<div className="page-container">
			<Banner
				component_id="billboard"
				placeholder="billboard"
				name="billboard"
				isMobile={prop.isMobile}
				class_name="ads-billboard"
			/>
			{prop.ArticleZone?.data?.meta?.code === 200 && (
				<SectionZone
					isMobile={prop.isMobile}
					ArticleZone={prop.ArticleZone}
				/>
			)}
			{prop.ArticlePopular?.data?.meta?.code === 200
			&& Array.isArray(prop.ArticlePopular?.data?.data)
			&& prop.ArticlePopular?.data?.data?.length > 0
			&& (
				<SectionHottest
					isMobile={prop.isMobile}
					ArticlePopular={prop.ArticlePopular}
				/>
			)}
			{prop.ArticleMost?.data?.meta?.code === 200 && (
				<SectionMostUpdate
					isMobile={prop.isMobile}
					ArticleMost={prop.ArticleMost}
				/>
			)}

			{prop.Review && prop.Review?.status !== "fetch_error" && (
				<SectionReview
					Review={prop.Review}
					handleReview={prop.handleReview}
					active={prop.active}

				/>
			)}
			{prop.ArticleCampaign && prop.ArticleCampaign?.status !== "fetch_error" && (
				<SectionPowerHour
					isMobile={prop.isMobile}
					ArticleCampaign={prop.ArticleCampaign}
				/>
			)}

			{discoverVideos?.status !== "fetch_error" && youtubeVideos?.status !== "fetch_error" && (
				<SectionYoutubeVideo />
			)}

			{prop.ArticleParenting && prop.ArticleParenting?.status !== "fetch_error" && (
				<SectionParenting
					isMobile={prop.isMobile}
					ArticleParenting={prop.ArticleParenting}
				/>
			)}

			{prop.ArticleEducation && prop.ArticleEducation?.status !== "fetch_error" && (
				<SectionEducation
					isMobile={prop.isMobile}
					ArticleEducation={prop.ArticleEducation}
				/>
			)}

			{arrayCheck(articleMidlife?.data?.data) && (
				<SectionMidlife articles={articleMidlife?.data?.data} />
			)}

			{prop.ArticleRelation && prop.ArticleRelation?.status !== "fetch_error" && (
				<SectionRelation
					isMobile={prop.isMobile}
					ArticleRelation={prop.ArticleRelation}
				/>
			)}

			{prop.Quiz && prop.Quiz?.status !== "fetch_error" && (
				<SectionQuiz
					isMobile={prop.isMobile}
					Quiz={prop.Quiz}
				/>
			)}

			{prop.ArticleDetik && prop.ArticleDetik?.length ? (
				<SectionDetik ArticleDetik={prop.ArticleDetik} />
			) : null}

			{prop.youtubeMeta && (
				<>
					<div className="homepage__divider-zigzag">
						<IconZigzag />
					</div>
					<SectionWhatsOnFd
						youtubeMeta={prop.youtubeMeta}
						buttonLink={env.WHATS_ON_FD_YOUTUBE_LINK}
					/>
				</>
			)}

			<div className="contain-title">
				<p>Mommies Daily</p>
				<h1>A friendly non-judgemental place to share about parenting, education, and equality</h1>
			</div>

			<style jsx>
				{`
					.contain-title {
						border-top: 1px solid #F0F3F7;
						max-width: 1280px;
						width: 1280px;
						margin: 80px auto 50px auto;
						padding: 40px 0 0 0;
					}
					.contain-title h1 {
						font-family: Roboto;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0em;
					}
					.contain-title p {
						font-family: Poppins;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						letter-spacing: 0em;
					}
					.desc {
						font-family: Roboto;
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: left;
						margin: 16px;
					}
					hr.hr1 {
						border-top: 0.5px solid #dfe3e8;
					}
					.page-container {
						// max-width: 480px;
						margin: 0 auto;
						padding-top: 60px;
					}
					.item-menu {
						font-family: Roboto;
						font-size: 16px;
						font-weight: 700;
						line-height: 12px;
						letter-spacing: 0em;
						text-align: left;
						color: #000;
						flex-shrink: 0;
						padding: 16px 0;
					}
					.menus {
						border-top: 1px solid #e0e0e0;
						display: flex;
						gap: 28px;
						overflow: scroll;
						margin: 16px;

						-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
					}

					.menus::-webkit-scrollbar {
						display: none;
					}

					.homepage__divider-zigzag {
						display: flex;
						align-items: center;
						justify-content: center;
						margin: var(--space-80) auto;
						padding-top: var(--space-36);
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
